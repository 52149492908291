<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
  <div cdkDragHandle class="flex justify-between items-baseline pr-4">
    <h2 mat-dialog-title>{{ quickLinkText ? 'CMS Check' :'Single Sign On'}}</h2>
    <button type="button" mat-icon-button class="leading-none" mat-dialog-close><mat-icon>close</mat-icon></button>
  </div>

  <mat-divider></mat-divider>

  <div mat-dialog-content>
    <p *ngIf="!quickLinkText">Select "Continue" to autosign onto the portal for <span class="font-bold">{{ carrierName }}</span></p>
    <p *ngIf="quickLinkText" [innerHTML]="quickLinkText | safeHtml"></p>
  </div>


  <div mat-dialog-actions align="end">
    <button type="button" mat-raised-button mat-dialog-close>Cancel</button>
    <button type="button" mat-flat-button mat-dialog-close (click)="submitSSO()">Continue</button>
  </div>

</div>
