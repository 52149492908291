<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
  <div cdkDragHandle class="flex justify-between items-baseline pr-4">
    <h2 mat-dialog-title>eSignature Scope of Appointment</h2>
    <button type="button" mat-icon-button class="leading-none" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-content>
    <p class="mb-3">The eSignature Scope of Appointment (SOA) provided here is a CMS-approved, proprietary SMS tool that
      is not specific
      to any plan sponsor and can be used for all appointments as per CMS Medicare Marketing Guidelines.
    </p>

    <form [formGroup]="remoteForms" autocomplete="off">
      <ng-container *ngIf="leadDetails?.emails?.length == 0 && !isGenerateDraft">
        <div class="flex gap-4">
          <mat-form-field class="grow">
            <mat-label>From</mat-label>
            <input formControlName="default_email" matInput  required [appInputMaxLength] />
          </mat-form-field>
          <mat-form-field class="grow-0">
            <mat-label>Type</mat-label>
            <mat-select disableOptionCentering formControlName="leaddefault_email_type" required>
              <mat-option value="Home" label=" Home"> Home</mat-option>
              <mat-option value="Other" label="Other">Other </mat-option>
              <mat-option value="Work" label="Work">Work</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="grow">
            <mat-label>To</mat-label>
            <input formControlName="leadEmail" matInput required  [appInputMaxLength] />
          </mat-form-field>
          </div>
      </ng-container>
      <ng-container *ngIf="leadDetails?.emails?.length > 0 || isGenerateDraft">
        <div class="flex gap-4">
          <mat-form-field class="grow">
            <mat-label>Appointment Date</mat-label>
            <input formControlName="aptDate" matInput [matDatepicker]="picker" [min]="minDate"  appDate  (input)="checkAptDate(remoteForms.value.aptDate)" (dateChange)="checkAptDate(remoteForms.value.aptDate)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="remoteForms.invalid">Please enter a valid date as "MM/DD/YYYY" between today and 1 year.</mat-error>
          </mat-form-field>
          <mat-form-field class="grow">
            <mat-label>Initial Method of Contact</mat-label>
            <mat-select disableOptionCentering formControlName="contactMethod" required>
              <mat-option value="Web" label=" Web"> Web</mat-option>
              <mat-option value="Email" label="Email">Email </mat-option>
              <mat-option value="Phone" label="Phone">Phone</mat-option>
              <mat-option value="Text" label="Text">Text</mat-option>
              <mat-option value="Walk in" label="Walk in">Walk in</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>
      <ng-container>
        <div class="flex" *ngIf="leadDetails?.emails?.length > 1">
          <mat-form-field class="w-1/2" >
            <mat-label>Please select lead email address</mat-label>
            <mat-select disableOptionCentering formControlName="emailIds">
              <mat-option *ngFor="let emails of leadDetails.emails" value="({{emails.email_type}}) {{emails.email}}">({{emails.email_type}}) {{emails.email}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>
      <ng-container>
        <div class="flex">
          <mat-form-field class="w-[50%]">
                <mat-label>Enter Agent Name Here to eSign</mat-label>
            <input formControlName="agentSignature" matInput  required  [appInputMaxLength] />
          </mat-form-field>
        </div>
      </ng-container>
    </form>
    <div class="pb-4" align="end">
      <button mat-raised-button mat-dialog-close>Cancel</button>
      <button *ngIf="remoteForms.valid && leadDetails?.emails?.length == 0 && !isGenerateDraft" [disabled]="generatingDraft" mat-flat-button color="primary" (click)="updateLeadEmail()" class="ml-2">{{!generatingDraft ? 'Save to Generate Draft' : 'Generating Draft...'}}</button>
      <button *ngIf="remoteForms.valid && leadDetails?.emails?.length > 0 || isGenerateDraft" [disabled]="isEmailSOA" mat-flat-button color="primary" (click)="remoteSOA()" class="ml-2">{{!isEmailSOA ? 'Email eSignature Scope of Appointment' : 'Processing'}}</button>
    </div>

    <div class="outer-box" *ngIf="leadDetails?.emails?.length || isGenerateDraft">
      <div class="inner-box">
        <table class="w-full">
          <tr>
            <td class="pr-1"><b>From:</b></td>
            <td>{{email_types.SENDER_EMAIL}}</td>
          </tr>
          <tr>
            <td class="pr-1"><b>To:</b> </td>
            <td>{{leadDetails?.default_email?.email}}</td>
          </tr>
          <tr>
            <td class="pr-1"><b>Subject:</b></td>
            <td>Medicare Health Plans - Scope of Appointment eSignature Request:
              for {{leadDetails?.fname.trim()}} {{leadDetails?.lname.trim()}}
              from {{userDetails?.fname.trim()}} {{userDetails?.lname.trim()}}
            </td>
          </tr>
          <tr>
            <td class="pr-1 align-top"><b>Message:</b></td>
            <td>
              <div>
                <div>
                  <p class="mb-3">Dear {{leadDetails?.fname}} {{leadDetails?.lname}},</p>
                  <p class="mb-3">Please click on the link below to be taken to a secure website where you can electronically sign the Scope of Appointment form required before we can discuss your Medicare health plan options.
                  </p>
                  <ul>
                    <li>Scope of Appointment Document</li>
                  </ul>
                  <p class="mb-3">If you have any questions, do not hesitate to contact me.</p>
                  <p class="mb-3">Sincerely,</p>
                  <p class="mb-3">{{userDetails?.fname}} {{userDetails?.lname}}</p>
                  <p>Licensed Insurance Agent</p>
                  <p>{{userDetails?.company}}</p>
                  <p *ngIf="userDetails?.default_phone && userDetails?.default_phone.phone && userDetails?.default_phone.phone.length>0">
                    {{userDetails?.default_phone.phone}}
                  </p>
                  <p>TTY 711</p>
                  <p *ngIf="userDetails?.default_email && userDetails?.default_email.email && userDetails?.default_email.email.length>0">
                    {{userDetails?.default_email.email}}
                  </p>
                  <p *ngIf="userDetails?.default_address && userDetails?.default_address.address1 && userDetails?.default_address.address1.length>0">
                    {{userDetails?.default_address.address1}}
                  </p>
                  <p *ngIf="userDetails?.default_address && userDetails?.default_address.city">
                    {{userDetails?.default_address.city}},
                    {{userDetails?.default_address.state_id}}
                    {{userDetails?.default_address.zip}}
                  </p>
                  <p class="my-3">
                    If you do not want to be contacted again via email, please send an email to
                    {{userDetails.default_email.email}} with the subject
                    line: Do Not Email Me Again.
                  </p>
                  <div>
                    <div *ngFor="let item of Object.keys(groupByCategory)">
                      <div *ngFor="let type of groupByCategory[item]">
                        <p class="my-3">
                          {{type.email_disclaimer_text_with_counts}}
                        </p>
                      </div>
                    </div>
                  </div>
                  <p class="mb-3">This e-mail, including any attachments, is intended solely for the use of the individual or entity to which it is addressed. If the reader of this e-mail is not the intended recipient, the reader is hereby notified that any dissemination, distribution, use or copying of this e-mail is strictly prohibited. If you have received this e-mail in error, please notify the sender by reply e-mail and destroy this e-mail immediately.
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
