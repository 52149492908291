<div class="rounded-md drop-shadow-md p-1 {{ type }} bg-white flex justify-between items-center gap-2">
  <div class="flex flex-row items-center w-full gap-2" [ngClass]="{ 'justify-center' : showIcon }">
    <ng-container *ngIf="showIcon">
      <app-sms-spinner size="sm" *ngIf="type === 'announcement'"></app-sms-spinner>
      <mat-icon *ngIf="type === 'alert'">warning</mat-icon>
      <mat-icon *ngIf="type === 'success'">check_circle</mat-icon>
    </ng-container>

    <div [ngClass]="{ 'mx-auto': !showIcon }">
      <ng-content></ng-content>
    </div>
  </div>

  <span class="float-right" *ngIf="canDismiss">
    <button mat-icon-button class="leading-none" (click)="closeNotice()"><mat-icon class="text-white">close</mat-icon></button>
  </span>

</div>
