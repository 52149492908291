<div class="loading-spinner-block color-flip">
  <div class="loading-spinner-block__icon {{size ? 'la-' + size : ''}} color-accent--dark">
    <div class="la-ball-spin-clockwise {{ size ? 'la-' + size : '' }}">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div class="loading-spinner-block__content">
    <div *ngIf="data">{{ data }}</div>
    <div *ngIf="!data">
      <ng-content></ng-content>
    </div>
  </div>
</div>
