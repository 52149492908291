import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LeadDetailComponent } from '@modules/lead/components/lead-detail/lead-detail.component';

@Component({
  selector: 'app-lead-missing-requirements',
  templateUrl: './lead-missing-requirements.component.html',
  styleUrls: ['./lead-missing-requirements.component.scss']
})
export class LeadMissingRequirementsComponent implements OnInit {
  public leadMissingReq: any = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public messingReq: any,
    public dialogRef: MatDialogRef<LeadMissingRequirementsComponent>,
    public dialog: MatDialog) {
    this.leadMissingReq = messingReq;
  }

  ngOnInit(): void { }

  public openUserProfile() {
    this.dialogRef.close();
    this.dialog.open(LeadDetailComponent, {
      width: '100%',
      data: this.leadMissingReq.lead_id,
    });
  }

}
