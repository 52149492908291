import { AuthService } from "@app/service/auth.service";
import { UserService } from "@data/services/user/user.service";
import { LaunchdarklyService } from "@shared/services/LaunchDarkly/launchdarkly.service";
import { CookieService } from "ngx-cookie-service";
import { first } from "rxjs";

export function initializeAppFactory(
  _authService: AuthService,
  _launchDarklySvc: LaunchdarklyService,
  _userSvc: UserService,
  _cookieService: CookieService,
): () => Promise<any> {

  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        const currentPage = window.location.pathname;
        if (currentPage !== '/registration.html')
          _authService.setCookie();

        let userId = _authService?.getToken()?.user_id;
        if (userId)
          _userSvc.getUserView(userId)
            .pipe(first())
            .subscribe({
              next: userDetails => _launchDarklySvc.init(userDetails) ? resolve(true) : reject(false)
            })

        else
          _launchDarklySvc.init(null) ? resolve(true) : reject(false)


      } catch (error) {
        reject(error);
      }
    });
  };
};