<form autocomplete="off" [formGroup]="phoneForm">
  <div class="flex justify-between items-baseline">
    <h4 class="user-profile-heading">Phones</h4>
    <button mat-icon-button matTooltip="Add Entry" [matMenuTriggerFor]="phoneMenu">
      <mat-icon class="plus">add</mat-icon>
    </button>
  </div>

  <div class="flex">
    <mat-menu #phoneMenu="matMenu">
      <ng-container *ngFor="let content of phone_content">
        <button *ngIf="!content.isSelected" (click)="addPhoneType(content)" mat-menu-item>{{content.text}}</button>
      </ng-container>
    </mat-menu>
  </div>

  <div formArrayName="phones">
    <ng-container *ngFor="let phone of phonesListArr?.controls; let i=index" [formGroupName]="i">
      <div class="flex gap-3 place-items-center">
        <mat-form-field class="w-[25%]">
          <mat-label>Type</mat-label>
          <mat-select disableOptionCentering matNativeControl formControlName="phone_type" [disabled]="phone?.value?.phone_class == 'true'" [ngClass]="{'line-through': phone?.value?.phone_class == 'true'}">
            <mat-option *ngFor="let content of phone_content" value="{{content.text}}">{{content.text}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="w-[43%]">
          <mat-label>Phone</mat-label>
          <input [class]="phone?.value?.phone_class == 'true' ? 'not-allowed' : ''"  matInput formControlName="phone" appPhoneNumberMask [maxlength]="14" [readonly]="phone?.value?.phone_class == 'true'" [ngClass]="{'line-through': phone?.value?.phone_class == 'true'}" appDisableAutofill>
          <mat-error *ngIf="phone?.get('phone')?.errors && submitted">
            Please enter correct phone number
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-[20%]">
          <mat-label>Ext ...</mat-label>
          <input [class]="phone?.value?.phone_class == 'true' ? 'not-allowed' : ''" [readonly]="phone?.value?.phone_class == 'true'"  matInput formControlName="phone_ext" [maxlength]="20" [ngClass]="{'line-through': phone?.value?.phone_class == 'true'}">
        </mat-form-field>
        <button mat-icon-button class="close" title="Undo" *ngIf="phone?.value?.phone_class == ''" (click)="clearPhone( phone.value,i)"><mat-icon>close</mat-icon></button>
        <button mat-icon-button *ngIf="phone?.value?.phone_class == 'true'" (click)="refreshPhone(phone.value,i)"><mat-icon>replay</mat-icon></button>
        <mat-checkbox *ngIf="type!='admin'" (change)="phoneCheckBoxvalue($event,phone.value,i)" [checked]="phone?.value?.isSelected==true || type == 'plan' || type=='add-user'" [disabled]="phone?.value?.isDisabled && phone?.value?.phone.length != 14"></mat-checkbox>
      </div>
    </ng-container>

  </div>
</form>
