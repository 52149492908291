import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject, Subject, first, takeUntil } from 'rxjs';

import { IRegistration } from '@data/services/registration/registration.interface';
import { RegistrationService } from '@data/services/registration/registration.service';
import { IValidateUrl } from '@data/services/registration/validate-url.interface';
import { environment } from '@env/environment';
import { UserAgreementComponent } from '@modules/registration/components/user-agreement/user-agreement.component';
import { ConfirmedValidator } from '@shared/helper/confirmed.validator';

import { validatorPatterns } from '@app/constants';
import * as stateData from '@data/states.json';
import { ToastMsgService } from '@shared/services/toastr/toast-msg.service';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss']
})
export class UserRegistrationComponent implements OnInit, OnDestroy {

  private _componentDestroyed$ = new Subject<boolean>();

  private _lastEnteredNpn: string = '';
  private _loginPattern = /^[a-zA-Z0-9]+$/;
  private _namePattern = /^[a-zA-Z]+.[a-zA-Z \-]*$/;
  private _pwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[!@#$&*])[A-Za-z\d!@#$&*].{7,}$/;
  private _phonePattern = /^[\(\)\d \-]+$/;
  private _token: string;

  public accountId: number;
  public accountOwnerName: string;
  public accountOwnerEmail: string;
  public accountOwnerPhone: string;

  public displayAcctInfo: boolean = false;
  public displayInvalidAcct: boolean = false;
  public displayRoleExpired: boolean = false;
  public hasError: boolean = false;

  public disabledAccount: boolean = false;

  public componentLoaded: boolean = false;
  public inProgress: boolean = false;
  public loadingMessage: string = '';

  public logo: string = 'assets/images/dummy-navBar-logo-transparent.png';
  public registrationAttempt: boolean = false;
  public registrationStatus: string;
  public statesList: Array<any>;
  public userRegistrationForm: FormGroup;
  public registrationMessage$ = new BehaviorSubject<string>('');

  public login: FormControl = new FormControl('', [Validators.required, Validators.pattern(this._loginPattern), Validators.minLength(6), Validators.maxLength(15)]);
  public password: FormControl = new FormControl('', [Validators.required, Validators.pattern(this._pwordPattern)]);
  public confirmPwd: FormControl = new FormControl('');
  public fname: FormControl = new FormControl('', [Validators.required, Validators.pattern(this._namePattern)]);
  public lname: FormControl = new FormControl('', [Validators.required, Validators.pattern(this._namePattern)]);
  public email: FormControl = new FormControl('', [Validators.required, Validators.pattern(validatorPatterns.EMAIL)]);
  public address1: FormControl = new FormControl('', [Validators.required]);
  public city: FormControl = new FormControl('', [Validators.required]);
  public state_id: FormControl = new FormControl('', [Validators.required]);
  public zip: FormControl = new FormControl('', [Validators.required, Validators.pattern('^[0-9]{5}$')]);
  public phone: FormControl = new FormControl('', [Validators.required, Validators.pattern(this._phonePattern)]);
  public mphone: FormControl = new FormControl('', [Validators.pattern(this._phonePattern)]);
  public fphone: FormControl = new FormControl('', [Validators.pattern(this._phonePattern)]);
  public npn: FormControl = new FormControl('', [Validators.required, Validators.pattern('^[0-9].+$')]);
  public hasNoNPN: FormControl = new FormControl(false);
  constructor(
    private _activeRoute: ActivatedRoute,
    private _dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private _registrationService: RegistrationService,
    private _toastMsg: ToastMsgService,
  ) {

    this.statesList =  (stateData as any).default;
    this.userRegistrationForm = this._formBuilder.group({
      // controls
      login: this.login,
      password: this.password,
      confirmPwd: this.confirmPwd,
      company: new FormControl(''),
      fname: this.fname,
      lname: this.lname,
      email: this.email,
      address1: this.address1,
      address2: new FormControl(''),
      city: this.city,
      state_id: this.state_id,
      zip: this.zip,
      phone: this.phone,
      mphone: this.mphone,
      fphone: this.fphone,
      npn: this.npn,
      hasNoNPN: this.hasNoNPN,
      website: new FormControl(''),
		}, {
      // options
      validators: [
        (formGroup: FormGroup) => {
          if (this.npn.value.length === 0 && this.hasNoNPN.value === false)
            this.npn.setErrors({ missingNPN: true });

          else
            this.npn.setErrors(null);

          return;
        },
        ConfirmedValidator('password', 'confirmPwd')
      ]
    });

  }


  ngOnInit(): void {
    this._activeRoute.queryParams
      .pipe(first())
      .subscribe(params => {
        this._token = params?.q;

        if (this._token && this._token.length > 0)
          this._registrationService.validateUrl(this._token)
            .pipe(first())
            .subscribe({
              next: (cobrandAccount: IValidateUrl) => {
                this._checkURLValidation(cobrandAccount)

              },
              error: (err : HttpErrorResponse) => {
                this.displayInvalidAcct = true;

              }
            })
            .add(() => {
              this.componentLoaded = true;
              if (this.displayInvalidAcct || this.displayRoleExpired)
                this.userRegistrationForm.disable({emitEvent: false});

            });

        else {
          this.componentLoaded = true;
          this.setLogo('assets/images/default-lapro-logo-clear.png');
        }

      });

    this.hasNoNPN.valueChanges
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe({
        next: (checked) => {
          if (checked) {
            this._lastEnteredNpn = this.npn.value;
            this.npn.setValue('', { emitEvent: false });

          } else {
            this.npn.setValue(this._lastEnteredNpn, { emitEvent: false });

          }
        }
      });

    this.userRegistrationForm.valueChanges
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe({
        next: () => {
          this.hasError = false;
          this.registrationMessage$.next('');
        }
      });

  }

  public setLogo(logoSource?: string) {
    this.logo = logoSource ? logoSource : this.logo;
  }

  public submitForm(): boolean {
    this.registrationAttempt = false;
    this.userRegistrationForm.markAllAsTouched();
    this.registrationMessage$.next('');

    if (this.userRegistrationForm.valid)
      return this._checkUserAgreement(this.userRegistrationForm.value);

    else {
      this.hasError = true;
      this._showRegistrationMessage('Please enter all the required fields before submission.');

      return false;
    }
  }

  private _checkURLValidation(cobrandAccount : IValidateUrl) {
    const msg = cobrandAccount.message ? cobrandAccount.message : '';
    const cobrandInfo = cobrandAccount?.cobrand;
    const cobrandOwner = cobrandAccount?.user;

    if (msg.indexOf("Role Expired") > -1) {
      this.displayRoleExpired = true;

    } else if (cobrandInfo && cobrandOwner) {
      this.displayAcctInfo = true;

      this.accountId = cobrandOwner.account_id;
      this.accountOwnerName = cobrandOwner.fname + " " + cobrandOwner.lname;
      this.accountOwnerEmail = cobrandOwner.default_email.email;
      this.accountOwnerPhone = cobrandOwner.default_phone.phone;

      // CoBranding Agency Logo Update
      if (cobrandInfo) {
        this.setLogo(cobrandInfo.cobrand_asset_urls?.logo_3);
      }
    }

  }

  private _checkUserAgreement(formValues: IRegistration): boolean {

      this._dialog.open(UserAgreementComponent, {
        width: '60%'
      }).afterClosed()
        .subscribe((result : any) => {

        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.userRegistrationForm.markAsPristine();

        if (result === true) {
          if (!this._token)
            this._registerUser({ ...formValues, bSignature: result });

          else
            this._registerUser({ ...formValues, bSignature: result, source: this._token, fromURL: true });

        } else {
          this.hasError = true;
          this._showRegistrationMessage('Your registration was not completed due to not accepting the web agreement. Please submit again if done in error or close page.');

        }
        return result;
      });

    return true;
  }

  private _registerUser(formValue: IRegistration) {
    this.userRegistrationForm.disable({ emitEvent: false });

    this.inProgress = true;
    this.loadingMessage = 'Creating a User Account. Please Wait.';

    formValue.phone = formValue.phone.replace(/[\(\)\s\-]*/g, '');
    formValue.mphone = formValue?.mphone.replace(/[\(\)\s\-]*/g, '');
    formValue.fphone = formValue?.fphone.replace(/[\(\)\s\-]*/g, '');

    this._registrationService.registerUser(formValue)
      .pipe(first())
      .subscribe({
        next: (response) => {
          this._processRegistrationResponse(response);
        },
        error: (response : HttpErrorResponse) => {
          this._processRegistrationResponse(response.error, true);
        }
      });
  }

  private _processRegistrationResponse(response : any, error: boolean = false) {
    this.hasError = response.success == 0 || error;
    this.inProgress = false;
    this.loadingMessage = '';

    if (response) {
      const msg = response.message;

      if (this.hasError) {
        this.userRegistrationForm.enable({ emitEvent: false });

        if (msg.indexOf('already in use') > -1) {
          //username already taken, prompt to pick a different one
          this._showRegistrationMessage('Username already exists. Choose a different username or click on <a target="_blank" href="' + environment.authClient + 'pwRequest' + '">forgot password</a> or contact us at 1-877-645-014.');

        } else {
          this._showRegistrationMessage(msg);

        }

      } else {

        //registration completed but account is disabled
        if (response.disabled == 'true')  {
          this.disabledAccount = true;
          this._showRegistrationMessage('Thank you for registering for Lead Advantage Pro. Your profile was created but made inaccessible until your credentials can be validated. You will be contacted within 24 hours or you can call 1-877-645-0147.');

        }

        else
          this._showRegistrationMessage('Your account has been created, please <a class="underline" href="' + environment.authClient + '">click here</a> to log on to Lead Advantage Pro.');

      }

    } else {
      this.userRegistrationForm.enable({ emitEvent: false });
      this._showRegistrationMessage('An unexpected error occurred. Please try again.');

    }

  }

  private _showRegistrationMessage(message: string) {
    this.registrationAttempt = true
    this._toastMsg.resetMessage();
    this.registrationMessage$.next(message);
  }

  public closeNotice(){
    this.displayInvalidAcct = false;
    this.displayRoleExpired = false;
    this.displayAcctInfo = false;
    this.registrationAttempt = false;
  }

  ngOnDestroy(): void {
    this._componentDestroyed$.next(true);
    this._componentDestroyed$.complete();
  }

}
