import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlMatcher } from '@angular/router';
import { PageNotFoundComponent } from '../app/core/error/pages/page-not-found/page-not-found.component';
import { ServerErrorComponent } from '../app/core/error/pages/server-error/server-error.component';
import { DownForMaintenanceComponent } from '../app/core/error/pages/down-for-maintenance/down-for-maintenance.component';
import { AuthGuard } from '@app/guard/auth.guard';

const routes: Routes = [
  {
    matcher: (url) => {
      return (url.length === 1 && url[0].path.match(/^registration.*/gm))
        ? { consumed: url }
        : null;
    },
    loadChildren: () =>
      import('./modules/registration/registration.module').then((m) => m.RegistrationModule),
  },
  {
    path: 'integration',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/integration/integration.module').then((m) => m.IntegrationModule)
  },
  {
    path: 'lead',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/lead/lead.module').then((m) => m.LeadModule),
  },
  {
    path: 'calendar',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/calendar/calendar.module').then((m) => m.CalendarModule),
  },
  {
    path: 'quotes',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/quotes/quotes.module').then(m => m.QuotesModule),
  },
  {
    path: 'forms/:tabName',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/forms/forms.module').then(m => m.FormsModule),
  },
  {
    path: 'reports',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'help',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/help/help.module').then((m) => m.HelpModule)
  },
  {
    path: 'privacy_policy',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule)
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: '404',
    pathMatch: 'full',
    component: PageNotFoundComponent,
  },
  {
    path: '500',
    pathMatch: 'full',
    component: ServerErrorComponent,
  },
  {
    path: 'down-for-maintenance',
    pathMatch: 'full',
    component: DownForMaintenanceComponent,
  },
  // {
  // 	path: "**",
  // 	pathMatch: "full",
  // 	component: PageNotFoundComponent,
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
      // enableTracing: true,
    }),
  ],
  exports: [RouterModule],

})

export class AppRoutingModule { }
