<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container" cdkDragHandle>
    <div class="loading-indicator" *ngIf="isLoading">
        <app-sms-spinner>
        </app-sms-spinner>
    </div>
    <app-sms-notice-banner *ngIf="errorMsg" type="alert" (closeBanner)="clearMessage()">
        <p>{{ errorMsg }}</p>
    </app-sms-notice-banner>
    <div mat-dialog-content>
        <div class="flex flex-row gap-4 items-start">
            <span class="text-4xl text-red-500"><mat-icon inline>warning</mat-icon></span>
            <div>
                <p>Are you sure you want to delete this Agent Writing Number record?</p>
            </div>
        </div>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-button mat-flat-button class="confirm-delete" (click)="deleteAgent()">Yes</button>
        <button mat-button mat-raised-button mat-dialog-close>No</button>
    </div>
</div>