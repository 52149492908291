import { Injectable } from '@angular/core';
import { throwError, Observable, Subject } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@app/service/auth.service';
import * as constants from '@app/constants';
import moment from 'moment';
import { BehaviorSubject, of } from 'rxjs';
import { ApplicationPlan, SOA } from '@data/lead/lead.interface';
import { LeadDetail } from '@data/lead/lead.interface';
import { SearchCriteria } from '@data/quick-search/quick-search.interface';
import { HttpHeaders } from '@angular/common/http';
import { search } from '@data/admin/leads.interface';
import { IDrug } from '@data/quotes/drug.interface';
@Injectable({
  providedIn: 'root',
})
export class LeadService {
  private alertSubject = new Subject<any>();
  private relatedSubject = new Subject<any>();
  private ledgerSubject = new Subject<any>();

  private showLead = new BehaviorSubject<any>("");
  private showConversation = new BehaviorSubject<any>("");
  private showApplication = new BehaviorSubject<any>("");
  private showFormChange = new BehaviorSubject<any>("");
  private showFormError = new BehaviorSubject<any>("");
  private showRelated = new BehaviorSubject<any>("");
  private isRelatedTable = new BehaviorSubject<any>("");
  private isApplicationTable = new BehaviorSubject<any>("");
  private refreshConvTable = new BehaviorSubject<any>("");
  private refreshLeadTable = new BehaviorSubject<any>("");
  private zipCodeIndex = new BehaviorSubject<any>("");
  public providerSubject = new Subject<any>();
  private isShrinkTable = new BehaviorSubject<any>("");
  private isCrossedItems = new BehaviorSubject<any>("");
  private isLeadListItem = new BehaviorSubject<any>("");

  private _leadDetail = new BehaviorSubject<LeadDetail|null>(null);

  public access_token: string = "";
  public lead_details!: LeadDetail;
  public leadSelected: boolean = false;
  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
  }

  public setLead(leadDetail: LeadDetail) {
    this._leadDetail.next(leadDetail);
  }

  public resetLead() {
    this._leadDetail.next(null);
  }

  public getCurrentLead(): LeadDetail|null {
    return this._leadDetail.getValue();
  }

  public changedLead() {
    return this._leadDetail.asObservable();
  }

  public sendLeadListAlert(data: any) {
    this.alertSubject.next(data);
  }

  public getLeadListAlert(): Observable<any> {
    return this.alertSubject.asObservable();
  }

  public sendRelatedAlert(data: any) {
    this.relatedSubject.next(data);
  }

  public getRelatedAlert(): Observable<any> {
    return this.relatedSubject.asObservable();
  }

  public sendLeadLedger(data: any) {
    this.ledgerSubject.next(data);
  }

  public getLeadLedger(): Observable<any> {
    return this.ledgerSubject.asObservable();
  }

  public setShrinkTable(data: any) {
    this.isShrinkTable.next(data);
  }

  public getShrinkTable(): Observable<any> {
    return this.isShrinkTable.asObservable();
  }

  /* start : code for to pass the message when to try to delete any
  form array value (phone, email & address) */
  public setCrossedItems(data: any) {
    this.isCrossedItems.next(data);
  }

  public getCrossedItems(): Observable<any> {
    return this.isCrossedItems.asObservable();
  }

  /* end */

  /* code for to pass the message when delete the lead and refresh the lead counts */

  public setLeadListItems(data: any) {
    this.isLeadListItem.next(data);
  }

  public getLeadListItems(): Observable<any> {
    return this.isLeadListItem.asObservable();
  }

  /* end */

  public deleteLeads(data: any) {
    return this.http.post(environment.api + 'lead/deleteLeads/', data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getUsers(account_id: string) {
    let input = {
      search_type: 'simple',
      criteria: {
        account_id: account_id,
      },
      field_list: 'user_id,fname,lname',
      page_number: 1,
      orderby_field: 'fname',
      orderby_direction: 'asc',
    };
    return this.http.post(environment.api + 'user/search', input).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getDisposition(data: any) {
    return this.http.post(environment.api + 'disposition/search/', data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getLeadApps(lead_id: string) {
    var data = {
      criteria: {
        lead_id: lead_id,
      },
      search_type: 'simple',
      orderby_field: 'user_id',
      orderby_direction: 'desc',
      field_list:
        'product_type_desc,app_id,plan_name,carrier_name,user_id,dispo_id,dispo_name,dispo_date',
    };
    return this.http.post(environment.api + 'lead/app/search/', data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public assignLeadsToUserByAdmin(data: any) {
    return this.http
      .post(environment.api + 'lead/assignLeadsToUserByAdmin/', data)
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public assignDispositionToLeads(data: any) {
    return this.http
      .post(environment.api + 'lead/assignDispositionToLeads/', data)
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public leadDisposition(lead_id: string, data: any) {
    return this.http
      .post(environment.api + 'lead/' + lead_id + '/disposition/', data)
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public leadAppointment(user_id: string, data: any) {
    return this.http
      .post(environment.api + 'user/' + user_id + '/appointment/', data)
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public exportLead(exportParams: any) {
    var params: any;
    switch (exportParams.export_type) {
      case 'account':
        params = {
          search_type: 'simple',
          criteria: {
            export_type: {
              type: 'simple',
              value: [constants.CONSTANTS.EXPORT_LEAD.account.export_type],
            },
            account_id: {
              type: 'simple',
              value: [exportParams.account_id],
            },
            user_id: {
              type: 'simple',
              value: [exportParams.user_id],
            },
          },
          field_list: '',
          page_number: 1,
          records_per_page: 10,
          include_deleted: 0,
        };
        break;
      case 'lead_list':
        params = {
          search_type: 'simple',
          criteria: {
            export_type: {
              type: 'simple',
              value: [constants.CONSTANTS.EXPORT_LEAD.lead_list.export_type],
            },
            account_id: {
              type: 'simple',
              value: [exportParams.account_id],
            },
            user_id: {
              type: 'simple',
              value: [exportParams.user_id],
            },
            lead_id_list: {
              type: 'set',
              value: exportParams.lead_list,
            },
          },
          field_list: '',
          page_number: 1,
          records_per_page: 10,
          include_deleted: 0,
        };
        break;
      case 'recent_lead':
        params = {
          search_type: 'simple',
          criteria: {
            export_type: {
              type: 'simple',
              value: [constants.CONSTANTS.EXPORT_LEAD.recent_lead.export_type],
            },
            account_id: {
              type: 'simple',
              value: [exportParams.account_id],
            },
            user_id: {
              type: 'simple',
              value: [exportParams.user_id],
            },
          },
          field_list: '',
          page_number: 1,
          records_per_page: 10,
          include_deleted: 0,
        };
        break;
      case 'search_criteria':
        params = exportParams.search_criteria;
        params.criteria.export_type = {
          type: 'simple',
          value: [constants.CONSTANTS.EXPORT_LEAD.search_criteria.export_type],
        };
        params.criteria.account_id = {
          type: 'simple',
          value: [exportParams.account_id],
        };
        params.criteria.user_id = {
          type: 'simple',
          value: [exportParams.user_id],
        };
        break;
    }
    return this.http.post(environment.api + 'leadExport/', params).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public exportLeadPlan(guidID: string) {
    return this.http.get(environment.api + 'leadPlanCurrent/export/' + guidID).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public addNewLead(params: any) {
    return this.http.post((environment.api) + "lead", params).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public getCountiesByZip(zip: number) {
    return this.http.get((environment.api) + `geo/countiesByZip/${zip}`).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public leadSearch(params: any) {
    return this.http.post((environment.api) + `lead/search/`, params).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public leadPlanCurrentSearch(leadId:string){
    let params = {
      "search_type": "simple",
      "criteria": {
          "lead_id": {
            "type": "simple",
            "value": [leadId]
          }
        },
      "records_per_page":"10",
      "page_number":"1",
      "field_list": ""
    };

    return this.http.post((environment.api) + `leadPlanCurrentCache/search/`, params).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public getConversationlogRecent(data: any) {
    return this.http.post(environment.api + 'conversationlog/recent', data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getLeadRecent(data: any, type: string) {
    return this.http.post(environment.api + 'lead/' + type + '/', data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getApplicationRecent(data: any) {
    return this.http.post(environment.api + 'app/recent/', data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public deleteApplication(lead_id : any, app_id: any) {
    return this.http.get(environment.api + 'lead/' + lead_id + '/app/' + app_id + '/delete/').pipe(
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }

  public deleteLeadPlanCurrent(lead_id : any, app_id: any) {
    return this.http.get(environment.api + 'lead/' + lead_id + '/leadPlanCurrent/' + app_id + '/delete/').pipe(
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }

  public searchLeadCurrentPlan(data: any) {
    return this.http.post(environment.api + 'leadPlanCurrent/planSearch', data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public addLeadCurrentPlan(lead_id: any, data: any) {
    return this.http.post(environment.api + 'lead/' + lead_id + '/leadPlanCurrent' , data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public leadCategoryCount(user_id: string) {
    var data = {
      user_id: [user_id]
    }
    return this.http.post((environment.api) + `leadCategoryCount`, data).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public getSoAeSignature(agentSignature: string) {
    let data = {
      "account_id": this.auth.account_id,
      "user_id": this.auth.getToken().user_id,
      "esignature_type_id": "1",
      "data": "json xml pdf binary",
      "signature": agentSignature,
      "signature_date": moment().format('LL')
    }
    return this.http.post((environment.api) + `esignature`, data).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public getLeadDetails(lead_id: string): Observable<any> {
    return this.http.get((environment.api) + `lead/` + lead_id).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public getDiscrepancy(lead_id: string): Observable<any> {
    return this.http.get((environment.api) + `lead/` + lead_id+'/mid').pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }


  public searchMIDLeads(lead_id: string): Observable<any> {
    return this.http.get((environment.api) + `lead/` + lead_id+'/mid/search/').pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public linkLeadToMID(lead_id: string,ssoValue:string){
    return this.http.get((environment.api) + `lead/` + lead_id+'/mid/link/'+ssoValue).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public unLinkLeadToMID(lead_id: string){
    return this.http.get((environment.api) + `lead/` + lead_id+'/mid/unlink/').pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public pushToMID(lead_id: string): Observable<any> {
    return this.http.get((environment.api) + `lead/` + lead_id+'/mid/push').pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public getb2bpartner(type: string) {
    let criteria;
    let account_id;
    if (type === 'new-lead') {
      criteria = {
        "search_type": "simple", "criteria": { "account_id": { "type": "simple", "value": [environment.INSURACTIVE_ACCOUNT_ID] } }, "field_list": ""
      }
      account_id = environment.INSURACTIVE_ACCOUNT_ID;
    } else {
      criteria = {
        "search_type": "simple",
        "criteria": {
          "account_id": {
            "type": "simple",
            "value": [this.auth.account_id]
          }
        },
        "field_list": "",
        "orderby_field": "company",
        "orderby_direction": "asc"
      }
      account_id = this.auth.account_id;
    }
    return this.http.post((environment.api) + `account/` + account_id + `/b2bpartner/search`, criteria).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public getRelatedLeads(lead_id: string, data: any) {
    return this.http.post(environment.api + 'lead/' + lead_id + '/leadRelated/search', data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getClientInvite(payload: any) {
    return this.http.post((environment.api) + `clientInvite`, payload).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public saveLeadDetails(lead_id: string, data: any) {
    return this.http.post((environment.api) + `lead/` + lead_id, data).pipe(
      first(),
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public createEmailDraft(params: any) {
    return this.http.post((environment.api) + `lead/${this.lead_details.lead_id}/email/`, params).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public addRelatedLeads(data: any) {
    return this.http.post(environment.api + 'lead/', data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getDispositionList(params: any) {
    return this.http.post((environment.api) + `disposition/search/`, params).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }


  public getTrackingCode(b2bpartner_id: string) {
    let criteria = {
      "search_type": "simple",
      "criteria": {
        "b2bpartner_id": {
          "type": "simple",
          "value": [b2bpartner_id]
        }
      },
      "field_list": "tracking_code, b2bpartner_id, active",
      "page_number": 1,
      "orderby_field": "tracking_code",
      "orderby_direction": "asc"
    }
    return this.http.post((environment.api) + `account/` + environment.INSURACTIVE_ACCOUNT_ID + `/b2bpartnerTrackingCode/search`, criteria).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public getDispositionAppData(params: any) {
    return this.http.post((environment.api) + `lead/app/search/`, params).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public deleteRelatedLeads(lead_id: string, related_lead_id: string) {
    return this.http.get(environment.api + 'lead/' + lead_id + '/leadRelated/' + related_lead_id + '/delete').pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public addDisposition(params: any) {
    return this.http.post((environment.api) + `lead/${this.lead_details.lead_id}/disposition/`, params).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public addAppointment(params: any) {
    return this.http.post((environment.api) + `user/${this.auth?.user_details?.user_id}/appointment/`, params).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public updateRelatedLeads(lead_id: string, related_lead_id: string, data: any) {
    return this.http.post(environment.api + 'lead/' + lead_id + '/leadRelated/' + related_lead_id, data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public leadImportFileTemplate() {
    return this.http.get(environment.api + 'leadImportFile/template').pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public leadVendorSearch(data: any) {
    return this.http.post(environment.api + 'LeadVendor/search', data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getEmailMsg(entity_id: string) {
    return this.http.get((environment.api) + `emailMessage/${entity_id}`).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public sendShowLead(input: string) {
    this.showLead.next(input);
  }

  public getShowLead(): Observable<any> {
    return this.showLead.asObservable();
  }

  public addSearchedRelatedLead(lead_id: string, data: any) {
    return this.http.post(environment.api + 'lead/' + lead_id + '/leadRelated', data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public addAppointmentLead(params: any, lead_id: string) {
    return this.http.post((environment.api) + `lead/${lead_id}/appointment/`, params).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public getAppointment_TaskData(id: string) {
    return this.http.get((environment.api) + `appointment/${id}`).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public deleteAppointment_Task(id: string) {
    return this.http.get(environment.api + `appointment/${id}/delete`).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getleadType() {
    return this.http.get(environment.api + `lead/types`).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getCampaignCode(data: any) {
    return this.http.post(environment.api + `account/campaign/search/`, data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getProductType(payload: SearchCriteria) {
    return this.http.post(environment.api + `productType/search`, payload).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getTagSearch(payload: SearchCriteria) {
    return this.http.post(environment.api + `tag/search/`, payload).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public sendShowConversation(input: string) {
    this.showConversation.next(input);
  }

  public getShowConversation(): Observable<any> {
    return this.showConversation.asObservable();
  }

  public sendShowApplication(input: string) {
    this.showApplication.next(input);
  }

  public getShowApplication(): Observable<any> {
    return this.showApplication.asObservable();
  }

  public addConversationNotes(data: any) {
    return this.http.post((environment.api) + `note`, data).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public getCampaignSegment(data: any) {
    return this.http.post((environment.api) + `campaignSegment/search/`, data).pipe(catchError(error => {
      return throwError(() => error);
    })
    );
  }

  public addConversationEmail(data: any) {
    return this.http.post((environment.api) + `emailMessage/send`, data).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public addConversationTask(user_id: string, data: any) {
    return this.http
      .post(environment.api + 'lead/' + user_id + '/appointment/', data)
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public leadImportFile(data: any) {
    return this.http.post(environment.api + 'leadImportFile/', data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public leadImportFileUpload(fileName: any, lead_import_file_id: string) {
    const formData = new FormData();
    formData.append('file', fileName[0]);
    const headers = new HttpHeaders({ 'Authorization': "Bearer" + " " + this.access_token, 'Content-Type': '' });
    return this.http.put(environment.api + 'leadImportFile/' + lead_import_file_id + '/fileUpload', formData, { headers: headers }).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public downloadExportFile(id: string) {

    return this.http.get(environment.api + 'leadExport/'+ id, {responseType: 'text'}).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public downloadExportPDPFile(id: string) {
    return this.http.get(environment.api + 'PDPRetentionReport/'+ id, {responseType: 'text'}).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public downloadLeadExportCurrentFile(id: string) {
    return this.http.get(environment.api + 'leadPlanCurrent/download/'+ id, {responseType: 'text'}).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public sendFormChangeAlert(input: string) {
    this.showFormChange.next(input);
  }

  public getFormChangeAlert(): Observable<any> {
    return this.showFormChange.asObservable();
  }

  public getContactPermissionHistory(lead_id: string, payload: any) {
    return this.http
      .post(environment.api + 'lead/' + lead_id + '/contactPermissionHistory/search', payload)
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public getNotesHistory(params: any) {
    return this.http
      .post(environment.api + 'note/search', params)
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public addApplicationNote(params: any) {
    return this.http.post((environment.api) + `note`, params).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public getApplicationNoteView(id: string) {
    return this.http.get((environment.api) + `note/${id}`).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }
  public getDispoApplication(id: string) {
    return this.http.get((environment.api) + `dispositionLog/${id}`).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public sendErrorAlert(input: string) {
    this.showFormError.next(input);
  }

  public getErrorAlert(): Observable<any> {
    return this.showFormError.asObservable();
  }

  public getApplicationMethod() {
    return this.http.get((environment.api) + `app/types`).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public getPlanDetails(plan_data: ApplicationPlan) {
    return this.http.get((environment.api) + `lead/` + plan_data.lead_id + `/app/` + plan_data.app_id + `?includeCurrentPlan=1`).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public saveWithStatus(lead_id: string, app_id: string, appUpdateObj: any) {
    return this.http.post((environment.api) + `lead/` + lead_id + `/app/` + app_id, appUpdateObj).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  public updatePlanDetails(plan_data: ApplicationPlan, formData: any) {
    return this.http.post((environment.api) + `lead/` + plan_data.lead_id + `/app/` + plan_data.app_id, formData).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    );
  }


  public getStatusHistory(params: any) {
    return this.http
      .post(environment.api + 'dispositionLog/search', params)
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public getAdvisor(id: string) {
    return this.http
      .get(environment.api + `referral/${id}`)
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public advisorSearch(params: any) {
    return this.http
      .post(environment.api + `referral/search`, params)
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public triggerSilverPopCall(params: any) {
    return this.http
      .post(environment.api + `Silverpop/`, params)
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public getAffiliateList() {
    return this.http
      .get(environment.api + `referral/getAffiliateList`)
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public connectAdvisor(referral_id: number, lead_id: string) {
    return this.http
      .get(environment.api + `referral/${referral_id}/lead/${lead_id}`)
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public sendShowRelated(input: string) {
    this.showRelated.next(input);
  }

  public getShowRelated(): Observable<any> {
    return this.showRelated.asObservable();
  }

  public sendRelatedTable(input: string) {
    this.isRelatedTable.next(input);
  }

  public getRelatedTable(): Observable<any> {
    return this.isRelatedTable.asObservable();
  }

  public sendApplicationTable(input: string) {
    this.isApplicationTable.next(input);
  }

  public getApplicationTable(): Observable<any> {
    return this.isApplicationTable.asObservable();
  }

  public sendRefreshConvTable(input: string) {
    this.refreshConvTable.next(input);
  }

  public getRefreshConvTable(): Observable<any> {
    return this.refreshConvTable.asObservable();
  }

  public getSOASearch(payload: SOA) {

    return this.http.post(environment.api + `soa/search`, payload).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getSOAPDF(soa_id: string) {
    return this.http.get(environment.api + `soa/` + soa_id + `/pdf/`).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public deleteDrug(leadId: string, drugId: string) {
    return this.http.get(environment.api + `lead/` + leadId + `/drug/` + drugId + `/delete`).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public addDrug(leadId: string, payload: any) {
    return this.http.post(environment.api + `lead/` + leadId + `/drug`, payload).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public bulkAddDrugs(leadId: string, payload: IDrug[]) {
    return this.http.post(environment.api + `lead/` + leadId + `/drug`, payload).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public selectDrug(leadId: string, drugId: any, payload: any) {
    return this.http.post(environment.api + `lead/` + leadId + `/drug/` + drugId, payload).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getClientCredentials(app_id: string) {
    return this.http.get(environment.api + 'clientInvite/searchByApp/' + app_id).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public leadAicoePdf(lead_id: string, app_id: string) {
    return this.http.get(environment.api + 'lead/' + lead_id + '/app/' + app_id + '/aicoe_data/pdf/', { responseType: 'arraybuffer' }).pipe(
      catchError((error) => {
        if (error.status === 404) {
          return of(undefined)
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  public enrollmentPDF(app_id: string) {
    return this.http.get(environment.api + 'app/' + app_id + '/enrollment/pdf/').pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getClientFirstVistiDate(payload: search) {
    return this.http.post(environment.api + 'clientInvite/search/', payload).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getCarrierPlanGeography(params:any,target:string="api") {
   var payload:any =  {
      "search_type": "simple",
      "criteria": this.generateSearchCriteria(params),
      "target":target
       }

    return this.http.post(environment.api + 'carrierPlanGeography/search/', payload).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public generateSearchCriteria(criteria:any): object {
  	let searchCriteria:any = {};
  	const simpleSearch = [
  		"user_id",
  		"lead_id",
  		"zip",
      "county"
    ]

  	for (const property in criteria) {
  		if (
  			criteria[property] === undefined ||
        criteria[property] === null ||
        criteria[property] === "" ||
        criteria[property] === 0 ||
        criteria[property].length === 0
  		) {
  			continue;
  		}
  	 if (simpleSearch.indexOf(property) > -1) {
  			searchCriteria[property] = {
  				type: "simple",
  				value: [criteria[property].toString()],
  			};
  		}
  	}
  	return searchCriteria;
  }

  public hraEnroll(app_id: string) {
    return this.http.get(environment.api + 'appHealthRiskAssessment/enroll/' + app_id).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public hraCheckStatus(app_id: string) {
    return this.http.get(environment.api + 'appHealthRiskAssessment/checkStatus/' + app_id).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

}
