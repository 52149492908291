<div class="relative">
  <div *ngIf="isLoading$ | async" class="loading-indicator">
    <div class="h-full flex items-center justify-center">
      <app-sms-spinner></app-sms-spinner>
    </div>
  </div>

  <app-sidebar [isExpanded]="isExpanded">
    <app-header class="full-width fixed top-0 z-20 drop-shadow-md" [ngClass]="{ 'expanded': isExpanded }"></app-header>

    <div class="p-1 relative overflow-y-auto" [ngClass]="{ 'mt-14 max-h-[calc(100vh-3.5rem)]': isProd(), 'mt-28 max-h-[calc(100vh-7rem)]': !isProd() }">
      <div class="w-[calc(100%-0.25rem)] absolute z-20" *ngIf="message$ | async as message">
        <app-sms-notice-banner *ngIf="message.text != ''" [type]="message.type" (closeBanner)="clearMessage()">
          <p>{{ message.text ? message.text : 'An error occurred, please try again' }}</p>
        </app-sms-notice-banner>
      </div>

      <router-outlet></router-outlet>
    </div>

  </app-sidebar>
</div>
