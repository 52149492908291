  <div mat-dialog-content class="flex">
    <app-sms-notice-banner *ngIf="errorMsg" type="alert" (closeBanner)="clearMessage()">
      <p>{{ errorMsg }}</p>
    </app-sms-notice-banner>
    <div class="w-full">
      <form [formGroup]="addAppointmentForm">
        <ng-container>
            <div class="flex mb-4 gap-4 place-items-center" *ngIf="isAssignLead && leadName==''">
              <p class="font-semibold">Assign Lead</p>
              <mat-icon-button class="cursor-pointer mt-1" title="Add Lead" type="button" mat-mini-fab color="primary" (click)="showSearchPanel()">
                <mat-icon>add</mat-icon>
              </mat-icon-button>
            </div>
            <div class="flex gap-2" *ngIf="leadName">
              <mat-form-field class="w-1/2">
                <mat-label>Lead Name</mat-label>
                <input matInput style="visibility: hidden" autocomplete="off" [(ngModel)]="leadName" [ngModelOptions]="{standalone: true}" />
                <span class="linked-td" (click)="goToLeadLedger(isFromCalendar?.extendedProps?.lead_id,$event)">
                  <a class="linked-td weight-td" href="lead/{{isFromCalendar?.extendedProps?.lead_id}}">{{leadName}}</a>
                </span>
              </mat-form-field>
              <mat-icon-button class="cursor-pointer place-content-center" title="Remove Lead" *ngIf="isAssignLead" type="button" mat-mini-fab color="warn" (click)="removeSelectedLead()">
                <mat-icon>remove</mat-icon>
              </mat-icon-button>
              <mat-icon-button class="cursor-pointer place-content-center" title="Add Lead" *ngIf="!isAssignLead && !appointment_id" type="button" mat-mini-fab color="primary" (click)="showSearchPanel()">
                <mat-icon>add</mat-icon>
              </mat-icon-button>
            </div>
          <div *ngIf="displayAssignLead" class="text-right">
            <mat-icon (click)="hideSearchPanel()">highlight_off</mat-icon>
          </div>

          <app-select-assign-lead [hidden]="!displayAssignLead" (lead)="selectLead($event)" [clearSearch]="clearSearch"></app-select-assign-lead>
        </ng-container>

        <div class="flex">
          <mat-form-field class="grow">
            <mat-label>Title</mat-label>
            <input matInput autocomplete="off" formControlName="title" (mousedown)="$event.stopPropagation()" [appInputMaxLength] />
          </mat-form-field>
        </div>

        <div class="flex py-2">
          <section class="w-[40%]">
            <mat-checkbox formControlName="allday_flag">All Day</mat-checkbox>
          </section>
          <section class="w-[60%] space-x-6 text-center">
            <mat-checkbox formControlName="importance">Important</mat-checkbox>
            <mat-checkbox formControlName="dismiss">Dismiss</mat-checkbox>
          </section>
        </div>
        <div class="flex gap-2 py-2">
          <mat-form-field class="grow">
            <mat-label>Start Date</mat-label>
            <input matInput formControlName="startDate" (mousedown)="$event.stopPropagation()" [matDatepicker]="startDate" autocomplete="off" appDate>
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="grow">
            <mat-label>Start Time</mat-label>
            <input formControlName="start_time" (mousedown)="$event.stopPropagation()" matInput type="time" autocomplete="off">
          </mat-form-field>
        </div>
        <div class="flex py-2 gap-2">
          <mat-form-field class="grow">
            <mat-label>End Date</mat-label>
            <input formControlName="endDate" matInput [matDatepicker]="endDate" (mousedown)="$event.stopPropagation()" autocomplete="off" appDate>
            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="grow">
            <mat-label>End Time</mat-label>
            <input formControlName="end_time" matInput type="time" (mousedown)="$event.stopPropagation()" autocomplete="off">
          </mat-form-field>
        </div>
        <div class="editor calendar-ngx-editor">
          <label>Note</label>
          <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
          <ngx-editor (mousedown)="$event.stopPropagation()" [editor]="editor" formControlName="description"></ngx-editor>
        </div>
      </form>
    </div>
  </div>

  <div mat-dialog-actions class="flex">
    <div class="grow">
      <button class="m-2 lap-warn" *ngIf="!isAddAppointment && !isConversationApt" mat-button mat-flat-button (click)="deleteApt()">Delete</button>
      <button *ngIf="!isAddAppointment && !isConversationApt" mat-button mat-raised-button (click)="copyAppointment()">Copy</button>
    </div>
    <div class="grow text-right">
      <button class="m-2" mat-button mat-raised-button (click)="closeModal()">{{isNewApt && !hasChange ? 'Close' : isNewApt && hasChange ? 'Cancel' : 'Cancel'}}</button>
      <button *ngIf="addAppointmentForm.valid && hasChange" mat-button mat-flat-button color="primary" (click)="addAppointment()" [disabled]="isButtonClicked">{{isNewApt ? 'Add' : 'Update'}}</button>
    </div>
  </div>

