import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AccountSettings } from '@data/admin/account-settings.interface';
import { IUserSettings } from '@data/user-profile/user-profile.interface';
import { environment } from '@env/environment';
import { PhoneDialerOptionComponent } from '@modules/integration/components/phone-dialer-option/phone-dialer-option.component';
import { ClickPhonePipe } from '@shared/pipes/click-phone.pipe';
import { ToastMsgService } from '@shared/services/toastr/toast-msg.service';
import { catchError, first, throwError } from 'rxjs';
import { PhoneDialerAuthSettings, PhoneDialerBehaviorType, PhoneDialerCallData, PhoneDialerSettings } from './phone-dialer.interface';

@Injectable({
  providedIn: 'root'
})
export class PhoneDialerService {

  private _prevURL: string = '';

  constructor(
    private _clickPhonePipe: ClickPhonePipe,
    private _dialog: MatDialog,
    private _http: HttpClient,
    private _router: Router,
    private _toastMsgSvc: ToastMsgService
  ) {}

  public getPhoneDialerBehaviourList() {
    return this._http.get<PhoneDialerBehaviorType[]>((environment.api) + "PhoneDialerProvider/listBehaviorTypes/"
      ).pipe(
        first(),
        catchError((error) => {
          return throwError(() => error);
        })
      )
  }

  public getOAuthSettings() {
    const options = {
      params: {
        laweb_current_route: this._prevURL
      }
    };

    return this._http.get<PhoneDialerAuthSettings>((environment.api) + 'PhoneDialerProvider/authSettings', options)
      .pipe(
        first(),
        catchError (error => {
          return throwError(() => error);
        })
    );
  }

  public isPhoneDialer(userSettings : IUserSettings, accountSettings: AccountSettings): boolean {
    return (accountSettings && userSettings)
      && accountSettings.phone_dialer_provider_active == '1'
      && userSettings.phone_dialer_provider_active == '1'
      && userSettings.phone_dialer_provider_behavior_type_id == '2';
  }

  public dialPhoneNumberBrowserDefault(telephonyPrefix: string, phoneNumber: string) {
    this._toastMsgSvc.showSuccess('Initiating call via default Application');

    setTimeout(() => {
      window.location.href = telephonyPrefix + ':' + this._clickPhonePipe.transform(phoneNumber);
    }, 3000);
  }

  public dialPhoneNumber(phoneNumber: string, leadId: number, userSettings : IUserSettings, accountSettings: AccountSettings) {
    const telephonyPrefix = accountSettings?.telephony_prefix || 'tel';
    if ((!accountSettings || !userSettings)
      || accountSettings.phone_dialer_provider_active == '0'
      || userSettings.phone_dialer_provider_active == '0'
      || userSettings.phone_dialer_provider_behavior_type_id === '1') {
      return this.dialPhoneNumberBrowserDefault(telephonyPrefix, phoneNumber);

    } else if (accountSettings.phone_dialer_provider_active == '1') {

      if (userSettings?.phone_dialer_provider_behavior_type_id == '2') {
        return this.createPhoneCall({
          callee_phone_number: phoneNumber,
          lead_id: leadId,
          user_id: userSettings.user_id!,
        });

      } else
        return this._getDialerBehavior(phoneNumber, leadId, userSettings, accountSettings);

    }
  }

  public createPhoneCall(params: PhoneDialerCallData) {
    this._toastMsgSvc.showAnnouncement('Initiating call via phone dialer. A call will start soon, please wait.');
    return this._http.post((environment.api) + 'PhoneDialerProvider/phoneCall', params)
      .pipe(
        first(),
        catchError (error => {
          return throwError(() => error.error);
        })
    );
  }

  public redirectToDialerLandingPage(errorMsg?: string) {
    this._setPrevURL();
    this._router.navigate(['/integration/phone-dialer-setup'], { state: { errorMsg: errorMsg }});
  }

  private _setPrevURL(): void {
    this._prevURL = window.location.href;
  }

  private _getDialerBehavior(phoneNumber: string, leadId: number, userSettings : IUserSettings, accountSettings: AccountSettings) {
    this._dialog.open(PhoneDialerOptionComponent, {
      width: '60%',
      minHeight: '500px',
      data: {
        accountSettings: accountSettings,
        leadId: leadId,
        phoneNumber: phoneNumber,
        userSettings: userSettings,
      }
    });
  }
}

