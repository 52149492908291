<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
  <div cdkDragHandle class="flex justify-between items-baseline pr-4">
    <h2 mat-dialog-title>Add Agent Writing Number</h2>
    <button type="button" mat-icon-button class="leading-none" mat-dialog-close><mat-icon>close</mat-icon></button>
  </div>

  <mat-divider></mat-divider>

  <mat-dialog-content>
    <ng-container *ngIf="message.type">
      <app-sms-notice-banner #messageBanner [type]="message.type" (closeBanner)="clearMessage(0)">
        <p>{{ message.text }}</p>
      </app-sms-notice-banner>
    </ng-container>
    <form [formGroup]="agentWritingForm">
      <div class="flex flex-row gap-3">
        <mat-form-field class="w-1/3">
          <mat-label>Carrier</mat-label>
          <mat-select disableOptionCentering matNativeControl formControlName="name" (selectionChange)="listActiveByCarrier($event)" required>
            <mat-option class="full-text" *ngFor="let elem of carrierData" [value]="elem.name">{{elem.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="w-1/3">
          <mat-label>Product Type</mat-label>
          <mat-select disableOptionCentering matNativeControl formControlName="product_type_desc" (selectionChange)="productTypeId($event)">
            <mat-optgroup label="---ACTIVE PRODUCT TYPES---">
              <mat-option *ngFor="let elem of activeProduct" [value]="elem.product_type_desc">{{elem.product_type_desc}}</mat-option>
            </mat-optgroup>

            <mat-optgroup label="---INACTIVE PRODUCT TYPES---" *ngIf="!showDefault">
              <mat-option *ngFor="let elem of inactiveProduct" [value]="elem">{{elem}}</mat-option>
            </mat-optgroup>

            <mat-optgroup label="---INACTIVE PRODUCT TYPES---" *ngIf="showDefault">
              <mat-option *ngFor="let elem of productType" [value]="elem.product_type_desc">{{elem.product_type_desc}}</mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="w-1/3">
          <mat-label>Agent Writing Number</mat-label>
          <input matInput autocomplete="off" formControlName="agent_writing_number">
        </mat-form-field>
      </div>
      <div *ngIf="agentWritingForm.value.product_type_desc ==  'Medicare Advantage' || agentWritingForm.value.product_type_desc == 'Medicare Part D'">
        <mat-form-field class="">
          <mat-label>Plan Year</mat-label>
          <mat-select disableOptionCentering matNativeControl formControlName="certification_year" required>
            <mat-option value=""></mat-option>
            <mat-option value={{year}}>{{year}}</mat-option>
            <mat-option value={{year1}}>{{year1}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="flex w-full justify-end">
      <button mat-button mat-raised-button mat-dialog-close>Cancel</button>
      <button type="button" mat-button mat-flat-button [disabled]="agentWritingForm.invalid" (click)="addAgent()">Add</button>
    </div>
  </mat-dialog-actions>
</div>
