<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <div cdkDragHandle class="flex justify-between items-baseline pr-4">
        <h2 mat-dialog-title>Create New Quick Link</h2>
        <button type="button" mat-icon-button class="leading-none" mat-dialog-close><mat-icon>close</mat-icon></button>
    </div>
    
        <mat-divider></mat-divider>


    <ng-container *ngIf="isLoading; else componentLoaded">
        <div class="loading-spinner">
            <app-sms-spinner [size]="'sm'">
                <span>Saving Changes</span>
            </app-sms-spinner>
        </div>

    </ng-container>

    <ng-template #componentLoaded>
        <mat-dialog-content class="mat-typography">
            <app-sms-notice-banner *ngIf="errorMessage.length > 0" type='alert' (closeBanner)="clearMessage(0)">{{ errorMessage }}</app-sms-notice-banner>
            <div class="basic-container">
                <form [formGroup]="addLinkForm">
                    <div class="flex flex-row gap-3">
                        <mat-form-field class="w-1/2">
                            <mat-label>Name</mat-label>
                            <input matInput formControlName="quicklink_title" autocomplete="off"
                                (mousedown)="$event.stopPropagation()" [appInputMaxLength] />
                            <mat-error *ngIf="checkError('quicklink_title', 'required')">
                                Quick Link Name is a required field.
                            </mat-error>
                            <mat-error *ngIf="checkError('quicklink_title', 'maxlength')">
                                The Quick Link title you entered exceeds the 100 character maximum limit. Please re-enter a title with
                                100 or fewer characters, including spaces.
                            </mat-error>
                        </mat-form-field>
                
                        <mat-form-field class="w-1/2">
                            <mat-label>URL</mat-label>
                            <input matInput formControlName="quicklink_url" autocomplete="off"
                                (mousedown)="$event.stopPropagation()" />
                            <mat-error *ngIf="checkError('quicklink_url', 'required')">
                                Quick Link URL is a required field.
                            </mat-error>
                            <mat-error *ngIf="checkError('quicklink_url', 'maxlength')">
                                The Quick Link URL you entered exceeds the 200 character maximum limit. Please re-enter a URL with 200
                                or fewer characters, including spaces.
                            </mat-error>
                        </mat-form-field>
                    </div>
                
                    <div *ngIf="availableToEdit" class="flex flex-wrap gap-3.5 lg:flex-row xs:flex-col items-start">
                        <div class="w-full">
                            <mat-checkbox class="checkbox-margin" formControlName="quicklink_hierarchy_type_id">
                                For all agents in account
                            </mat-checkbox>
                        </div>
                    </div>
                </form>
            </div>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <button mat-raised-button mat-dialog-close>Cancel</button>
            <button mat-flat-button color="primary" [disabled]="addLinkForm.invalid" (click)="saveLink()">Save</button>
        </mat-dialog-actions>

    </ng-template>

</div>
