<form [formGroup]="addTaskForm">
  <div mat-dialog-content>
    <app-sms-notice-banner *ngIf="errorMsg" type="alert" (closeBanner)="clearMessage()">
      <p>{{ errorMsg }}</p>
    </app-sms-notice-banner>
    <ng-container class="flex">
      <div class="w-full">
          <ng-container *ngIf="isAssignLead && leadName==''">
            <div class="flex mb-4 gap-4 place-items-center" *ngIf="isAssignLead && leadName==''">
              <p class="font-semibold">Assign Lead</p>
              <mat-icon-button class="cursor-pointer mt-1" title="Add Lead" type="button" mat-mini-fab color="primary" (click)="showSearchPanel()">
                <mat-icon>add</mat-icon>
              </mat-icon-button>
            </div>
          </ng-container>
          <div class="flex gap-2" *ngIf="leadName">
            <mat-form-field class="w-1/2">
              <mat-label>Lead Name</mat-label>
              <input matInput style="visibility: hidden" autocomplete="off" [(ngModel)]="leadName" [ngModelOptions]="{standalone: true}" />
              <span class="linked-td" (click)="goToLeadLedger(isFromCalendar?.extendedProps?.lead_id,$event)">
                <a class="linked-td weight-td" href="lead/{{isFromCalendar?.extendedProps?.lead_id}}">{{leadName}}</a>
              </span>
            </mat-form-field>
            <mat-icon-button class="cursor-pointer place-content-center" title="Remove Lead" *ngIf="isAssignLead" type="button" mat-mini-fab color="warn" (click)="removeSelectedLead()">
              <mat-icon>remove</mat-icon>
            </mat-icon-button>
            <mat-icon-button class="cursor-pointer place-content-center" title="Add Lead" *ngIf="!isAssignLead && !appointment_id" type="button" mat-mini-fab color="primary" (click)="showSearchPanel()">
              <mat-icon>add</mat-icon>
            </mat-icon-button>
          </div>
      </div>
      <div *ngIf="displayAssignLead" class="text-right">
        <mat-icon (click)="hideSearchPanel()">highlight_off</mat-icon>
      </div>
      <app-select-assign-lead [hidden]="!displayAssignLead" (lead)="selectLead($event)" [clearSearch]="clearSearch"></app-select-assign-lead>
    </ng-container>
    <div>
      <div>
        <mat-form-field class="w-full">
          <mat-label>Title</mat-label>
          <input matInput autocomplete="off" formControlName="title" (mousedown)="$event.stopPropagation()" [appInputMaxLength] />
        </mat-form-field>
      </div>
    </div>
    <div class="flex space-x-2 mb-2">
      <section class="grow">
        <mat-checkbox formControlName="completed">Completed</mat-checkbox>
      </section>
      <section class="grow">
        <mat-checkbox formControlName="importance">Important</mat-checkbox>
      </section>
    </div>
    <div class="flex space-x-4">
      <mat-form-field class="grow">
        <mat-label>Due Date</mat-label>
        <input matInput [matDatepicker]="dueDate" (mousedown)="$event.stopPropagation()" formControlName="startDate" (input)="dateCheck($event)" (dateChange)="dateCheck($event)" autocomplete="off" appDate>
        <mat-datepicker-toggle matSuffix [for]="dueDate"></mat-datepicker-toggle>
        <mat-datepicker #dueDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="grow">
        <mat-label>Due Time</mat-label>
        <input formControlName="start_time" (mousedown)="$event.stopPropagation()" matInput type="time" (keyup)="timeCheck($event)" (change)="timeCheck($event)" autocomplete="off">
      </mat-form-field>
    </div>
    <div class="editor calendar-ngx-editor">
      <label>Note/Details</label>
      <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
      <ngx-editor (mousedown)="$event.stopPropagation()" [editor]="editor" formControlName="description"></ngx-editor>
    </div>
  </div>
  <div mat-dialog-actions class="flex">
    <div class="grow">
      <button class="mr-2 lap-warn" *ngIf="!isAddTask && !isConversationTask" mat-button mat-flat-button (click)="deleteTask()">Delete</button>
      <button *ngIf="!isAddTask && !isConversationTask" mat-button mat-raised-button (click)="copyTask()">Copy</button>
    </div>
    <div class="grow text-right">
      <button class="mr-2" mat-button mat-raised-button (click)="closeModal()">{{isNewApt && !hasChange ? 'Close' : isNewApt && hasChange ? 'Cancel' : 'Cancel'}}</button>
      <button *ngIf="addTaskForm.valid && isDateTime && hasChange" mat-button mat-flat-button color="primary" (click)="addTask()" [disabled]="isButtonClicked">{{isNewApt ? 'Add' : 'Update'}}</button>
    </div>
  </div>
</form>
