import { DialogRef } from '@angular/cdk/dialog';
import { DatePipe } from '@angular/common';
import { AfterContentInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '@app/service/auth.service';
import { AdminService } from '@data/services/admin/admin.service';
import { HeaderService } from '@data/services/header/header.service';
import { LeadService } from '@data/services/lead/lead.service';
import { UserService } from '@data/services/user/user.service';
import * as data from '@data/states.json';
import { addressType, Criteria, emailType, IUser, phoneType } from '@data/user-profile/user-profile.interface';
import { ConfirmedValidator } from '@shared/helper/confirmed.validator';
import { CommonService } from '@shared/services/common/common.service';
import { MomentService } from '@shared/services/moment/moment.service';
import { interval, Subscription, forkJoin, takeUntil, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { AgentWritingNumberComponent } from '@modules/admin/components/users/agent-writing-number/agent-writing-number.component';
import { DeleteAgentComponent } from '@modules/admin/components/users/delete-agent/delete-agent.component';
import { ConfirmDeleteComponent } from '@shared/modals/confirm-delete/confirm-delete.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ToastMsgService } from '@shared/services/toastr/toast-msg.service';
import { validatorPatterns } from '@app/constants';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, AfterContentInit {
  private getUserDetailSubs: Subscription;
  private getAccountDetailSubs: Subscription;
  private getUserCarrierSubs: Subscription;
  private getUserRolesSubs: Subscription;
  private deleteAddressSubs: Subscription;
  private deleteEmailSubs: Subscription;
  private deletePhoneSubs: Subscription;
  private newUserSubs: Subscription;
  private updateUserSubs: Subscription;
  private getEmailTypeSubs: Subscription;
  private getPhoneTypeSubs: Subscription;
  private getAddressTypeSubs: Subscription;
  private isFormChangeSubs: Subscription;
  private userDetailsRoleSubs: Subscription;
  private updatePasswordSubs: Subscription;
  private _componentDestroyed$ = new Subject<boolean>();
  private phone_types: phoneType;
  private email_types: emailType;
  private address_type: addressType;
  private deleteAgent: any = [];
  private agentWritingNumberArray: any = [];
  private requests_list: any = [];
  public userDetailArray: any = [];
  public accountDetail: any = [];
  public userProfileForm: UntypedFormGroup;
  public applicationDataSource = new MatTableDataSource<[]>;
  public phone_content = ['Home', 'Fax', 'Mobile', 'Other', 'Work'];
  public email_content = ['Home', 'Other', 'Work'];
  public address_content = ['Home', 'Billing', 'Business', 'Mailing', 'Other'];
  public applicationDisplayedColumns: string[] = ['carrier_full_name', 'product_type_desc', 'agent_writing_number', 'certification_year'];
  public state_data: any = [];
  public showSubmenu: boolean = false;
  public isAgentWritingNumber: boolean = false;
  public hasChange: boolean = false;
  public isLoading: boolean = true;
  public submitted: boolean = false;
  private cmSyncIgnore: string;
  public totalRecords = 0;
  public hideStatus: boolean = false;
  // public errorMsg: string | null = null;
  public isAccountOwner: string | null = null;
  public rolesData: any = [];
  public isLoggedInUser: boolean = false;
  public isCommonFlag: boolean = false;
  public message: any = {};
  public checkboxStates: any = {};
  public inputTypeCurrent: any = 'password';
  public inputTypeNew: any = 'password';
  public inputTypeConfirm: any = 'password';
  public showCurrentPassword: boolean = false;
  public showNewPassword: boolean = false;
  public showConfirmPassword: boolean = false;
  public initialChange: boolean = false;

  @ViewChild('State') State: any;
  @ViewChild(MatPaginator) agentWritingPagination!: MatPaginator;
  @ViewChild('agentWritingSort') agentWritingSort!: MatSort;


  constructor(
    @Inject(MAT_DIALOG_DATA) public userData: any,
    private fb: UntypedFormBuilder,
    private headerService: HeaderService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private dialogRef: MatDialogRef<UserProfileComponent>,
    private userService: UserService,
    private momentService: MomentService,
    private leadService: LeadService,
    private adminService: AdminService,
    private commonService: CommonService,
    private http: HttpClient,
    private toasMsgService: ToastMsgService,
    public authService: AuthService
  ) {
    if (this.userData?.type == 'sms-users') {
      this.cmSyncIgnore = this.userData?.cm_sync_ignore;
      this.applicationDisplayedColumns.splice(0, 0, 'record_num');
    }
    this.userProfileForm = this.fb.group({
      fname: new FormControl('', Validators.required),
      lname: new FormControl('', Validators.required),
      gender: new FormControl(''),
      company: new FormControl(''),
      phones: this.fb.array([this.newPhones()]),
      emails: this.fb.array([this.newEmail()]),
      addresses: this.fb.array([this.newAddress()]),
      username: new FormControl(''),
      user_id: new FormControl(''),
      cm_sync_ignore: new FormControl(''),
      role_name: new FormControl('Agent', Validators.required),
      registration_date: new FormControl(''),
      last_access_date: new FormControl(''),
      active: new FormControl(true),
      npn: new FormControl(''),
      account_id: new FormControl(''),
      account_business_name: new FormControl(''),
      last_active_date_local: new FormControl(''),
      reference_id: new FormControl(''),
      currentpassword: new FormControl('', Validators.compose([
      ])),
      newpassword: new FormControl('', Validators.compose([
        Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%#*?&])[A-Za-z\d$@$!%#*?&].{7,}$")
      ])),
      confirmpassword: new FormControl('', Validators.compose([
      ]))
    }, {
      validator: ConfirmedValidator('newpassword', 'confirmpassword')
    });
    if (!userData?.user_id) {
      this.userProfileForm.addControl('loginName', new FormControl('', Validators.required))
    }
    this.isFormChangeSubs = this.leadService.getFormChangeAlert().subscribe(response => {
      if (response) {
        this.hasChange = true;
        if (response == 'valueChange') {
          this.initialChange = true;
        }
      }
    });
  }

  public ngOnChanges() {
    this.onCreateGroupFormValueChange();
    this.applicationDataSource.paginator = this.agentWritingPagination;
    this.applicationDataSource.sort = this.agentWritingSort;
  }

  public ngAfterContentInit() {
    this.applicationDataSource.paginator = this.agentWritingPagination;
    this.applicationDataSource.sort = this.agentWritingSort;
  }

  //function to detect any changes in user profile form
  public onCreateGroupFormValueChange() {
    const initialValue = this.userProfileForm.value;
    this.userProfileForm.valueChanges.pipe(takeUntil(this._componentDestroyed$)).subscribe(value => {
      if (this.userProfileForm.dirty) {
        this.initialChange = true;
        this.hasChange = Object.keys(initialValue).some(key => this.userProfileForm.value[key] !=
          initialValue[key])
      }
      if (this.userProfileForm.valid) {
        this.hasChangeDetect();
      }
    });
    this.hasChangeDetect();
  }

  // Hide & show password function
  public hideShowPassword = (value: any) => {
    if (value === 'current') {
      this.showCurrentPassword = (this.inputTypeCurrent == 'password');
      this.inputTypeCurrent = (this.inputTypeCurrent == 'password') ? 'text' : 'password';
    } else if (value === 'new') {
      this.showNewPassword = (this.inputTypeNew == 'password');
      this.inputTypeNew = (this.inputTypeNew == 'password') ? 'text' : 'password';
    } else {
      this.showConfirmPassword = (this.inputTypeConfirm == 'password');
      this.inputTypeConfirm = (this.inputTypeConfirm == 'password') ? 'text' : 'password';
    }
  };

  // function to detect whether the form array fields are valid or not
  private hasChangeDetect() {
    this.isFormChangeSubs = this.leadService.getFormChangeAlert().subscribe(response => {
      let deletePhones = this.userProfileForm.value.phones;
      let deletePhone = deletePhones.filter((obj: any) => obj.phone_class != '');
      let deleteAddresses = this.userProfileForm.value.addresses;
      let deleteAddress = deleteAddresses.filter((obj: any) => obj.address_class != '');
      let deleteEmails = this.userProfileForm.value.emails;
      let deleteEmail = deleteEmails.filter((obj: any) => obj.email_class != '');
      if (deletePhone.length != deletePhones.length && deleteEmail.length != deleteEmails.length && deleteAddress.length != deleteAddresses.length) {
        this.hasChange = true;
      }
      else {
        this.hasChange = false
      }

    });
  }

  public ngOnInit(): void {
    this.hasChange = false;
    this.initialChange = false;
    this.userProfileForm.patchValue({ currentpassword: '', newpassword: '', confirmpassword: '' });
    /* get states data */
    this.state_data = (data as any).default;
    this.getEmailTypeData();
    this.getPhoneTypeData();
    this.getAddressTypeData();
    this.getUserRoles();
    if (this.userData?.user_id) {
      this.getUserDetails();
    } else {
      this.isCommonFlag = true;
      this.getAccountDetail(this.authService.account_id);
    }
    this.onCreateGroupFormValueChange();

    this.isLoggedInUser = (this.authService.getToken().user_id == this.userData?.user_id);

    window.addEventListener('beforeunload', (event) => {
      if(!this.userProfileForm.pristine) {
         event.returnValue = 'You have unsaved content, are you sure you want to exit?';
       }
     });

    this.userProfileForm.get("username")?.disable();
    this.userProfileForm.get("user_id")?.disable();
    this.userProfileForm.get("last_active_date_local")?.disable();
    this.userProfileForm.get("registration_date")?.disable();
    this.userProfileForm.get("last_access_date")?.disable();
    this.userProfileForm.get("reference_id")?.disable();
    this.userProfileForm.get("account_id")?.disable();
    this.userProfileForm.get("account_business_name")?.disable();
    if (this.userData?.user_id == this.authService.getToken().user_id) {
      this.userProfileForm.get("role_name")?.disable();
    }
    if (this.userData?.type == 'sms-users') {
      this.userProfileForm.get("reference_id")?.enable();
    }
  }

  /* ::::::::::::::::::::::::::::: add phone functionality ::::::::::::::::::::::::::::: */

  public get phones(): UntypedFormArray {
    return this.userProfileForm.get("phones") as UntypedFormArray
  }


  private newPhones() {
    return this.fb.group({
      phone_type: ['Home'],
      phone: new FormControl('', [Validators.required, Validators.minLength(14)]),
      phone_ext: [''],
      phone_id: [''],
      phone_class: [''],
      isSelected: ""
    });
  }

  /* ::::::::::::::::::::::::::::: end phone functionality ::::::::::::::::::::::::::::: */

  /* ::::::::::::::::::::::::::::: add email functionality ::::::::::::::::::::::::::::: */

  public get emails(): UntypedFormArray {
    return this.userProfileForm.get("emails") as UntypedFormArray
  }

  private newEmail() {
    return this.fb.group({
      email_type: ['Home'],
      email: new FormControl('', [Validators.required, Validators.pattern(validatorPatterns.EMAIL)]),
      email_id: [''],
      email_class: [''],
      isSelected: ""
    });
  }

  /* ::::::::::::::::::::::::::::: end email functionality ::::::::::::::::::::::::::::: */

  /* ::::::::::::::::::::::::::::: add address functionality ::::::::::::::::::::::::::::: */

  public get addresses(): UntypedFormArray {
    return this.userProfileForm.get("addresses") as UntypedFormArray
  }

  private newAddress() {
    return this.fb.group({
      address_type_id: ['Home'],
      address1: new FormControl('', [Validators.required]),
      address2: '',
      city: new FormControl('', [Validators.required]),
      state_id: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required, Validators.minLength(5)]),
      address_id: [''],
      apo: [''],
      country_id: [''],
      county_fips: [''],
      zip_4: [''],
      address_class: [''],
      isSelected: ""
    });
  }

  /* ::::::::::::::::::::::::::::: end address functionality ::::::::::::::::::::::::::::: */

  // function to get user details
  private getUserDetails() {
    this.getUserDetailSubs = this.userService.getUserView(this.userData?.user_id).subscribe({
      next: (response: any) => {
        if (response) {
          this.userDetailArray = response;
          if (this.userDetailArray) {
            this.userProfileForm.patchValue({
              fname: this.userDetailArray.fname,
              lname: this.userDetailArray.lname,
              gender: this.userDetailArray.gender,
              company: this.userDetailArray.company,
              username: this.userDetailArray.usercredentials[0]?.login,
              user_id: this.userDetailArray.user_id,
              cm_sync_ignore: this.cmSyncIgnore == '0' ? true : false,
              role_name: this.userDetailArray.role_name,
              registration_date: this.datePipe.transform(this.userDetailArray.registration_date, 'MM/dd/yyyy'),
              last_access_date: this.datePipe.transform(this.userDetailArray.last_access_date, 'MM/dd/yyyy'),
              npn: this.userDetailArray.npn,
              account_id: this.userDetailArray.account_id,

              last_active_date_local: this.datePipe.transform(this.userDetailArray.last_active_date_local, 'MM/dd/yyyy'),
              reference_id: this.userDetailArray.sms_id,
              active: this.userDetailArray.active == 0 ? false : true // (this.userDetailArray.active == 1)
            });
            this.userProfileForm.setControl('phones', this.commonService.setExistingPhone(this.userDetailArray.phones, this.userDetailArray.default_phone.phone_id, this.userData?.user_id ? '' : ''))
            this.userProfileForm.setControl('emails', this.commonService.setExistingEmail(this.userDetailArray.emails, this.userDetailArray.default_email.email_id, this.userData?.user_id ? '' : ''))
            this.userProfileForm.setControl('addresses', this.commonService.setExistingAddress(this.userDetailArray.addresses, this.userDetailArray.default_address.address_id, this.userData?.user_id ? '' : ''))
            this.isCommonFlag = true;
            this.adminService.adminFormArray("refreshAdmin");
          }
          this.getAccountDetail(this.userDetailArray.account_id);
          this.getAgentWritingNumber();
          this.hasChange = false;
        }
      },
      error: (err) => {
        this.showErrorMessage(err.error.message);
      },
      complete: () => {
        this.hasChange = false;
      }
    });
  }

  // function to get agent writing numbers
  private getAgentWritingNumber() {
    if (this.userDetailArray) {
      let obj1: Criteria = {
        user_id: { "type": "simple", "value": [this.userDetailArray.user_id] },
      };
      if (!this.userDetailArray.user_id) delete obj1['user_id'];
      let obj =
      {
        "search_type": "simple",
        "criteria": obj1,
        "field_list": "user_carrier_id,agent_writing_number,carrier_name,carrier_full_name,product_type_id,product_type_name,product_type_desc,carrier_id,certification_year"
      }

      this.getUserCarrierSubs = this.headerService.getAgentWritingNumberDetail(obj).subscribe({
        next: (response: any) => {
          if (response.result == "") {
            this.isAgentWritingNumber = true;
            // update MatTableDataSource
            this.applicationDataSource.data = [];
          }
          else {
            this.isAgentWritingNumber = false;
            this.agentWritingNumberArray = response;
            // update MatTableDataSource
            this.applicationDataSource.data = this.agentWritingNumberArray;
            this.agentWritingNumberArray.sort((a: any, b: any) => a.carrier_full_name.localeCompare(b.carrier_full_name))
            this.totalRecords = response.length;
            this.applicationDataSource.paginator = this.agentWritingPagination;
            this.applicationDataSource.sort = this.agentWritingSort;

            const sortState: Sort = { active: 'carrier_full_name', direction: 'asc' };
            if (this.agentWritingSort) {
              this.agentWritingSort.active = sortState.active;
              this.agentWritingSort.direction = sortState.direction;
              this.agentWritingSort.sortChange.emit(sortState);
            }
          }
        },
        error: (err) => {
          this.showErrorMessage(err.error.message);
        },
        complete: () => {
          this.hasChange = false;
        }
      });
    }
  }

  // function to hide/show agent writing section
  public openMenu() {
    this.showSubmenu = !this.showSubmenu;
    this.applicationDataSource.paginator = this.agentWritingPagination;
    this.applicationDataSource.sort = this.agentWritingSort;
  }

  // function to get the account details
  private getAccountDetail(id: any) {
    this.getAccountDetailSubs = this.headerService.getAccountDetail(id).subscribe({
      next: (response: any) => {
        if (response) {
          this.accountDetail = response;
          if (this.userDetailArray.user_id == this.accountDetail.account_owner_user_id) {
            this.isAccountOwner = "YES"
          } else {
            this.isAccountOwner = "NO"
          }
          this.userProfileForm.patchValue({
            account_id: this.accountDetail.account_id,
            account_business_name: this.accountDetail.account_owner_fname + " " + this.accountDetail.account_owner_lname,
          });
          this.hasChange = false;
          this.isLoading = false;
        }
      },
      error: (err) => { this.isLoading = false; this.showErrorMessage(err.error.message); },
      complete: () => {
        this.hasChange = false;
      }
    });
  }

  private getUserRoles() {
    let payload = {
      "criteria": {
        "account_id": {
          "type": "simple",
          "value": [this.userData?.type == 'sms-users' ? this.userData?.account_id : this.authService.account_id]
        }
      },
      "field_list": "",
      "page_number": 1,
      "search_type": "simple",
    }
    this.getUserRolesSubs = this.userService.getUserRoles(payload, this.authService.account_id).subscribe({
      next: (res: any) => {
        this.rolesData = res
      },
      error: (err) => {
        this.showErrorMessage(err.error.message)
      },
      complete: () => { }

    })
  }

  //function to update the user profile
  public update(formData: any, type: any) {
    if (this.userProfileForm.invalid) {
      this.submitted = true;
      return;
    }
    this.isLoading = true;
    let newAddressObj: any;
    let newEmailObj: any;
    let newPhoneObj: any;

    let addressArray = formData.addresses;
    let defaultAddress = addressArray.filter((obj: any) => obj.isSelected == true);
    addressArray = addressArray.filter((obj: any) => obj.address_class == '');

    //call delete API the uncheck address
    let deleteAddresses = formData.addresses;
    let deleteAddress = deleteAddresses.filter((obj: any) => obj.address_class != '');
    let request;
    if (deleteAddress) {
      deleteAddress.forEach((element: any) => {
        request = this.http.get(environment.api + 'user/' + this.userData?.user_id + '/address/' + element.address_id + '/delete/');
        if (request) this.requests_list.push(request);
      });
    }

    if (addressArray) {
      newAddressObj = this.commonService.getAddresses(addressArray);
    }

    let emailArray = formData.emails;
    emailArray = emailArray.filter((obj: any) => obj.email_class == '');

    let defaultEmail = emailArray.filter((obj: any) => obj.isSelected == true);
    //call delete API the uncheck email
    let deleteEmails = formData.emails;
    let deleteEmail = deleteEmails.filter((obj: any) => obj.email_class != '');
    if (deleteEmail) {
      deleteEmail.forEach((element: any) => {
        request = this.http.get(environment.api + 'user/' + this.userData?.user_id + '/email/' + element.email_id + '/delete/');
        if (request) this.requests_list.push(request);
      });
    }

    if (emailArray) {
      newEmailObj = this.commonService.getEmails(emailArray);
      newEmailObj = newEmailObj.filter((element: any) => element.email != "")
    }

    let phoneArray = formData.phones;
    phoneArray = phoneArray.filter((obj: any) => obj.phone_class == '');

    let defaultPhone = phoneArray.filter((obj: any) => obj.isSelected == true);
    //call delete API the uncheck phone
    let deletePhones = formData.phones;
    let deletePhone = deletePhones.filter((obj: any) => obj.phone_class != '');
    if (deletePhone) {
      deletePhone.forEach((element: any) => {
        request = this.http.get(environment.api + 'user/' + this.userData?.user_id + '/phone/' + element.phone_id + '/delete/');
        if (request) this.requests_list.push(request);
      });
    }

    this.cmSyncIgnore = this.userProfileForm.value.cm_sync_ignore == false ? '1' : '0'

    if (phoneArray) {
      newPhoneObj = this.commonService.getPhones(phoneArray);
      newPhoneObj = newPhoneObj.filter((element: any) => element.phone != "")
    }

    if (this.requests_list.length > 0) {
      forkJoin(this.requests_list).subscribe({
        next: (response: any) => {
          this.updateUserProfileData(formData, type, newAddressObj, newPhoneObj, newEmailObj, defaultPhone, defaultEmail, defaultAddress)
        },
        error: (err: any) => { this.isLoading = false; this.showErrorMessage(err.error.message); },
        complete: () => { }
      });
    } else {
      const formData = this.userProfileForm.getRawValue();
      this.updateUserProfileData(formData, type, newAddressObj, newPhoneObj, newEmailObj, defaultPhone, defaultEmail, defaultAddress)
    }
  }

  private updateUserProfileData(formData: any, type: any, newAddressObj: any, newPhoneObj: any,
    newEmailObj: any, defaultPhone: any, defaultEmail: any, defaultAddress: any) {
    let role_selected = this.rolesData.filter((elem: any) => {
      return elem.role_name == formData.role_name
    })
    if (!this.userData?.user_id) {
      let obj = {
        account_id: formData.account_id,
        addresses: newAddressObj,
        default_address_id: "",
        default_address: {},
        phones: newPhoneObj,
        default_phone_id: "",
        default_phone: {},
        emails: newEmailObj,
        default_email_id: "",
        default_email: {},
        role_id: role_selected[0].role_id,
        fname: formData.fname,
        lname: formData.lname,
        gender: formData.gender,
        npn: formData.npn,
        company: formData.company,
        usercredentials: [{
          login: formData?.loginName,
          password: this.generateRandomPassword(),
          context: "LApro"
        }
        ],
        registration_date: formData.registration_date || this.momentService.serverDateTime(new Date())
      }
      this.newUserSubs = this.userService.addNewUser(obj).subscribe({
        next: (response: any) => {
          if (response) {
            this.userData.user_id = response?.user_id;
            this.userService.userInvite(this.userData.user_id).subscribe({
              next: (elem) => {
                this.message = {
                  type: 'success',
                  text: `New User has been created for account ${this.authService.account_id}.`
                }
                this.clearMessage(15000);
              },
              error: (err) => {
                this.showErrorMessage(err.error.message);
              },
              complete: () => { }
            })
            this.submitted = false;
            this.hideMsg();
            this.userDetailArray = [];
            this.getUserDetails();
            this.isLoading = false;
            if (type == 'close') {
              this.dialogRef.close(response);
              this.dialog.closeAll();
            }else{
              this.userProfileForm.markAsPristine();
            }
          }
        },
        error: (err) => {
          this.isLoading = false;
          this.submitted = false;
          this.showErrorMessage(err.error.message);
        },
        complete: () => {
          this.hasChange = false;
        }
      });
    } else {
      let default_phone: any;
      default_phone = {
        default_phone: true,
        phone: defaultPhone[0]?.phone ? defaultPhone[0]?.phone : newPhoneObj[0]?.phone,
        phone_ext: defaultPhone[0]?.phone_ext ? defaultPhone[0]?.phone_ext : newPhoneObj[0]?.phone_ext,
        phone_id: defaultPhone[0]?.phone_id ? defaultPhone[0]?.phone_id : newPhoneObj[0]?.phone_id,
        phone_type: defaultPhone[0]?.phone_type ? defaultPhone[0]?.phone_type : newPhoneObj[0]?.phone_type,
      }
      if (default_phone?.phone == '') {
        default_phone = {};
      }
      let obj: IUser = {
        sms_id: formData.reference_id,
        ...(this.userData?.type == 'sms-users') && { cm_sync_ignore: this.cmSyncIgnore },
        npn: formData.npn,
        company: formData.company,
        ein: this.userDetailArray.ein,
        environment_id: this.userDetailArray.environment_id,
        fname: formData.fname,
        lname: formData.lname,
        gender: formData.gender,
        account_id: formData.account_id,
        account_business_name: this.accountDetail.account_business_name,
        role_id: role_selected[0].role_id,
        role_name: role_selected[0].role_name,
        registration_date: this.momentService.serverDateTime(formData.registration_date),
        expire_date: formData.expire_date,
        last_access_date: formData.last_access_date ? formData.last_access_date : '',
        active: formData.active == true ? '1' : '0',
        appointment_count: this.userDetailArray.appointment_count,
        addresses: newAddressObj,
        emails: newEmailObj,
        phones: newPhoneObj[0]?.phone ? newPhoneObj : [],
        default_phone: default_phone,
        default_email: {
          default_email: true,
          email: defaultEmail[0]?.email ? defaultEmail[0]?.email : newEmailObj[0]?.email,
          email_id: defaultEmail[0]?.email_id ? defaultEmail[0]?.email_id : newEmailObj[0]?.email_id,
          email_type: defaultEmail[0]?.email_type ? defaultEmail[0]?.email_type : newEmailObj[0]?.email_type,

        },
        default_address: {
          default_address: true,
          address1: defaultAddress[0]?.address1 ? defaultAddress[0]?.address1 : newAddressObj[0]?.address1,
          address2: defaultAddress[0]?.address2 ? defaultAddress[0]?.address2 : newAddressObj[0]?.address2,
          address_id: defaultAddress[0]?.address_id ? defaultAddress[0]?.address_id : newAddressObj[0]?.address_id,
          address_type_id: defaultAddress[0]?.address_type_id ? defaultAddress[0]?.address_type_id : newAddressObj[0]?.address_type_id,
          apo: defaultAddress[0]?.apo ? defaultAddress[0]?.apo : newAddressObj[0]?.apo,
          city: defaultAddress[0]?.city ? defaultAddress[0]?.city : newAddressObj[0]?.city,
          country_id: defaultAddress[0]?.country_id ? defaultAddress[0]?.country_id : newAddressObj[0]?.country_id,
          county_fips: defaultAddress[0]?.county_fips ? defaultAddress[0]?.county_fips : newAddressObj[0]?.county_fips,
          state_id: defaultAddress[0]?.state_id ? defaultAddress[0]?.state_id : newAddressObj[0]?.state_id,
          zip: defaultAddress[0]?.zip ? defaultAddress[0]?.zip : newAddressObj[0]?.zip,
          zip_4: defaultAddress[0]?.zip_4 ? defaultAddress[0]?.zip_4 : newAddressObj[0]?.zip_4
        },
        usercredentials: [{
          login: this.userDetailArray.usercredentials[0].login,
          password: formData.newpassword,
          context: "LApro",
          user_credentials_id: this.userDetailArray.usercredentials[0].user_credentials_id
        }],
        user_id: this.userDetailArray.user_id
      }

      if (!formData.newpassword) delete obj['usercredentials'];
      if (formData.currentpassword && formData.newpassword && (formData.newpassword == formData.confirmpassword)) {
        let usercredentials = this.userDetailArray.usercredentials[0];
        let credentialsParams = {
          "login": usercredentials.login,
          "password": formData.currentpassword,
          "context": "LApro",
          "new_password": formData.newpassword
        }
        this.updatePasswordSubs = this.userService.updateExistingCredentials(formData.user_id, usercredentials.user_credentials_id, credentialsParams).subscribe({
          next: (response: any) => {
            this.userUpdate(obj, type, this.userData.user_id)
            setTimeout(() => {
              this.userProfileForm.patchValue({ currentpassword: '', newpassword: '', confirmpassword: '' });
              this.initialChange = false;
              this.hasChange = false;
            }, 3000);
          },
          error: (err: any) => {
            this.isLoading = false;
            this.showErrorMessage(err.error.message);
            this.userProfileForm.get('currentpassword')?.setErrors({ invalid: true });
          },
          complete: () => {
            this.hasChange = false;
            this.initialChange = false;
          }
        })
      } else {
        this.userUpdate(obj, type, this.userData.user_id)
      }
    }
  }

  private userUpdate(obj: any, type: any, userID: any) {
    this.updateUserSubs = this.headerService.updateUser(obj, userID).subscribe({
      next: (response: any) => {
        if (response) {
          if (this.userData?.type == 'sms-users')
                this.userData.dataChanged = true;

          if (this.userData?.user_id == this.authService.getToken().user_id) {
            this.authService.user_details = response;
            this.authService.userDetails$.next(response);
            this.userDetailsRoleSubs = this.userService.getUserDetailsAndPermissions(this.userData?.user_id).subscribe({
              next: (response: any) => {
                this.authService.user_details['rolePermission'] = JSON.parse(response.headers.get('x-lapro-acl-array') || '[]');
                this.authService.user_details['hash'] = response.headers.get('x-lapro-acl');
                this.leadService.sendFormChangeAlert('')
                if (type == 'close') {
                  this.dialog.closeAll();
                  this.toasMsgService.showSuccess("Your profile has been updated.");
                } else {
                  this.userProfileForm.markAsPristine();
                  this.getUserDetails();
                }
              },
              error: (err) => {
                this.isLoading = false;
                this.showErrorMessage(err.error.message);
              },
              complete: () => { this.hasChange = false; }
            })
          }else{
            if (type == 'close') {
              if (this.userData?.type == 'sms-users' && this.userData.dataChanged)
              this.dialogRef.close(true);
              else
              this.dialog.closeAll();
              this.toasMsgService.showSuccess("Your profile has been updated.");
            } else {
              this.userProfileForm.markAsPristine();
              this.getUserDetails();
            }
          }
          this.requests_list = [];
          this.submitted = false;
          this.message = {
            type: 'success',
            text: `Your profile has been updated.`
          }
          this.clearMessage(15000);
          this.isLoading = false;
        }
      },
      error: (err) => {
        this.isLoading = false;
        this.submitted = false;
        this.showErrorMessage(err.error.message);
      },
      complete: () => {
        this.hasChange = false;
        this.initialChange = false;
      }
    });
  }

  private generateRandomPassword() {
    var lowerletters = "abcdefghijklmnopqrstuvwxyz";
    var upperletters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var digits = "0123456789";
    var splChar = "@#$%^&*!"
    var text = "";
    for (var i = 0; i < 6; i++)
      text += lowerletters.charAt(Math.floor(Math.random() * lowerletters.length));
    for (var i = 0; i < 2; i++)
      text += digits.charAt(Math.floor(Math.random() * digits.length));
    for (var i = 0; i < 2; i++)
      text += upperletters.charAt(Math.floor(Math.random() * upperletters.length));
    for (var i = 0; i < 2; i++)
      text += splChar.charAt(Math.floor(Math.random() * splChar.length));
    return text;
  }

  // function to get the email dropdown types
  private getEmailTypeData() {
    this.getEmailTypeSubs = this.headerService.getEmailTypes().subscribe({
      next: (response: any) => {
        if (response) {
          this.email_types = response;
        }
      },
      error: (err) => { this.showErrorMessage(err.error.message); },
      complete: () => { }
    });
  }

  // function to get the phone dropdown types
  private getPhoneTypeData() {
    this.getPhoneTypeSubs = this.headerService.getPhoneTypes().subscribe({
      next: (response: any) => {
        if (response) {
          this.phone_types = response;
        }
      },
      error: (err) => { this.showErrorMessage(err.error.message); },
      complete: () => { }
    });
  }

  // function to get the address dropdown types
  private getAddressTypeData() {
    this.getAddressTypeSubs = this.headerService.getAddressTypes().subscribe({
      next: (response: any) => {
        if (response) {
          this.address_type = response;
        }
      },
      error: (err) => { this.showErrorMessage(err.error.message); },
      complete: () => { }
    });
  }

  //method to display agent writing popup
  public openAgentPopup() {
    const agentDialog = this.dialog.open(AgentWritingNumberComponent, {
      width: '55%',
      data: this.userData?.user_id
    })
    agentDialog.afterClosed().pipe(takeUntil(this._componentDestroyed$)).subscribe((res: any) => {
      if (res) {
        this.getAgentWritingNumber();
      }
    })
  }

  // method to get the id of the checkbox selected
  public getRowId(event: MatCheckboxChange, row: any) {
    this.hasChange = true
    if (event.checked) {
      this.deleteAgent.push(row)
    }
    else {
      let index = this.deleteAgent.indexOf(row)
      if (index > -1) {
        this.deleteAgent.splice(index, 1)
      }
    }
  }

  //method to display delete agent popup
  public deleteAgentPopup() {
    if (this.deleteAgent.length >= 1) {
      const deleteAgentPopup = this.dialog.open(DeleteAgentComponent, {
        width: '30%',
        data: this.deleteAgent
      })
      deleteAgentPopup.afterClosed().pipe(takeUntil(this._componentDestroyed$)).subscribe((elem: any) => {
        if (elem) {
          this.getAgentWritingNumber();
          this.deleteAgent = []
        }
      })
    }
    else {

      const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
        width: '30%',
        data: {
          'text': 'You have to select atleast one carrier to delete',
          'type': 'ok'
        }
      });
    }

  }

  //hide success message after 2 seconds
  private hideMsg() {
    this.hideStatus = true;
    setTimeout(() => {
      this.hideStatus = false;
    }, 15000);
  }

  private showErrorMessage(errorMsg: string) {
    this.message = {
      type: 'alert',
      text: errorMsg
    }
    this.clearMessage(30000);
  }

  /* clear notice message */
  public clearMessage(timer: number) {
    setTimeout(() => {
      this.message = this.toasMsgService.resetMessage();
    }, timer);
  }

  public captureClose(){
    if(!this.userProfileForm.pristine){
      this.commonService.unsavedChangesNotice();
    }else{
      if (this.userData?.type == 'sms-users' && this.userData?.dataChanged)
      {
        this.dialogRef.close(true);
      }else
      this.dialog.closeAll();
    }
  }

  public ngOnDestroy() {
    this._componentDestroyed$.next(true);
    this._componentDestroyed$.complete();
    if (this.getUserDetailSubs) {
      this.getUserDetailSubs.unsubscribe();
    }
    if (this.getAccountDetailSubs) {
      this.getAccountDetailSubs.unsubscribe();
    }
    if (this.newUserSubs) {
      this.newUserSubs.unsubscribe();
    }
    if (this.getEmailTypeSubs) {
      this.getEmailTypeSubs.unsubscribe();
    }
    if (this.getPhoneTypeSubs) {
      this.getPhoneTypeSubs.unsubscribe();
    }
    if (this.getUserCarrierSubs) {
      this.getUserCarrierSubs.unsubscribe();
    }
    if (this.getAddressTypeSubs) {
      this.getAddressTypeSubs.unsubscribe();
    }
    if (this.getUserRolesSubs) {
      this.getUserRolesSubs.unsubscribe();
    }
    if (this.deleteAddressSubs) {
      this.deleteAddressSubs.unsubscribe();
    }
    if (this.deleteEmailSubs) {
      this.deleteEmailSubs.unsubscribe();
    }
    if (this.deletePhoneSubs) {
      this.deletePhoneSubs.unsubscribe();
    }
    if (this.updateUserSubs) {
      this.updateUserSubs.unsubscribe();
    }
    if (this.isFormChangeSubs) {
      this.isFormChangeSubs.unsubscribe();
    }
    if (this.userDetailsRoleSubs) {
      this.userDetailsRoleSubs.unsubscribe();
    }
    if (this.updatePasswordSubs) {
      this.updatePasswordSubs.unsubscribe();
    }
    this.userProfileForm.markAsPristine();
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.userProfileForm.controls[controlName].hasError(errorName);
  }
}
