import { Component, HostListener, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@app/service/auth.service';
import { LeadDetail } from '@data/lead/lead.interface';
import { LeadService } from '@data/services/lead/lead.service';
import { ViewSwapperService } from '@data/services/quotes/view-swapper.service';
import { environment } from '@env/environment';
import { AddEventComponent } from '@modules/calendar/pages/add-event/add-event.component';
import { NewLeadComponent } from '@modules/lead/components/new-lead/new-lead.component';
import { CommonService } from '@shared/services/common/common.service';
import { ToastMsgService } from '@shared/services/toastr/toast-msg.service';
import { combineLatest, filter, first, Subject, Subscription, takeUntil } from 'rxjs';
import packageInfo from '../../../../package.json';
import { QuickLinksComponent } from './quick-links/quick-links.component';
import { AccountService } from '@data/services/admin/account.service';
import { IUser } from '@data/user-profile/user-profile.interface';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  private flag: boolean = false; //flag to check popover open ot not
  private _componentDestroyed$ = new Subject<boolean>();
  private _cobrandSub: Subscription;

  public defaultLogo: string = 'assets/images/dummy-navBar-logo-transparent.png';
  public defaultLogoSmall: string = 'assets/images/dummy-navBar-Logo-transparent-s.png';

  public versionNumber: string;
  public quote_url: string = '/quotes';
  public admin_url: string = '/admin/users/manage_user';
  public lead_url: string = '/lead';
  public calendar_url: string = '/calendar';
  public report_url: string = '/reports/self-service'
  public form_url: string = '/forms/medicare';
  public showSidebar: boolean;
  public navigateURL : string;
  public leadDetails: LeadDetail | null;

  @Input() isExpanded: boolean = false;
  @ViewChild(QuickLinksComponent, { static: false }) quickLinkComponent!: QuickLinksComponent;
  @ViewChild('sidenav') sidenav: MatSidenavModule;
  @ViewChild('panel', { static: true }) private sidePanel: MatSidenav;
  @ViewChild('content', { static: true, read: ViewContainerRef }) private vcf: ViewContainerRef;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private toastMsgService : ToastMsgService,
    public authService: AuthService,
    public leadService: LeadService,
    public commonService: CommonService,
    private sidenavService: ViewSwapperService,
    private _accountService: AccountService,
  ) {
    this.versionNumber = packageInfo.appRelease || '';

    this.leadService.changedLead()
      .pipe(
        takeUntil(this._componentDestroyed$),
      )
      .subscribe({
        next: (leadDetails) => {
          this.leadDetails = leadDetails
        }
      })

    combineLatest([
      this.authService.userDetails$,
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          first(),
        )
    ]).pipe(
      takeUntil(this._componentDestroyed$),
    ).subscribe({
      next: ([userDetails, navigation]) => {
        const url = navigation.url
        this.showSidebar = url.indexOf('registration') < 0;
        this.navigateURL = url;

        if (this.showSidebar && userDetails?.account_id)
          this._getCobrandDetails(userDetails!.account_id)
      }
    });

  }

  @HostListener('window:resize', ['$event'])
  public onResize(event:any): void {
    if (this.sidenav !== undefined) {
      this.isExpanded = !(event.target.innerWidth <= 980);

      this.commonService.setDashboardUI((event.target.innerWidth <= 665));
      this.commonService.setMenuExpand(this.isExpanded);
      this.toastMsgService.isExpanded = this.isExpanded;
    }
  }

  public ngOnInit(): void {
    this.sidenavService.setPanel(this.sidePanel);
    this.sidenavService.setContentVcf(this.vcf);
  }

  public ngOnDestroy(): void {
    this._componentDestroyed$.next(true);
    this._componentDestroyed$.complete();

    if(this._cobrandSub)
      this._cobrandSub.unsubscribe();
  }

  public isProd() {
    return environment.production;
  }

  public openQuickLinks() {
    this.dialog.open(QuickLinksComponent, {
      width: '500px',
    });
  }

  public addLead(e: Event) {
    this.preventRedirectButton(e);

    this.dialog.open(NewLeadComponent, {
      width: '60%'
    });
  }

  public addEvent(e: Event) {
    this.preventRedirectButton(e);

    let obj = {
      tabName: 'Appointment',
      type: 'add',
      ...(this.router.url == '/') && { activeModule : 'dashboard'}
    }
    this.dialog.open(AddEventComponent, {
      width: '55%',
      data: obj
    });
  }

  public addQuote(isLead: boolean = false) {
    this.leadService.leadSelected = isLead;
    this.quote_url = isLead ? '/quotes/mapartd/' + this.leadDetails?.lead_id : '/quotes';
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([this.quote_url]);
    });
  }

  public preventRedirectButton(e: Event) {
    e.preventDefault();
    e.stopImmediatePropagation();
  }

  public redirectTo(url: string) {
    const currentUrl = url;
    if (currentUrl == '/quotes') {
      this.leadService.leadSelected = false;
    }

    // code to navigate to the selected tab
    this.router.navigateByUrl(currentUrl, { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      })
    });

  }

  public isActiveUrl(activeTab: string) {
    if (activeTab == '/lead') {
      return this.router.url.startsWith('/lead');

    } else {
      return this.router.url.includes(activeTab);

    }

  }

  // method to redirect to dashboard
  public redirectToMain() {
    this.router.navigate(['/'])
  }

  private _getCobrandDetails(accountId: string) {
    this._accountService.getLoggedInCobrandDetails(accountId)
      .pipe(takeUntil(this._componentDestroyed$))
      .subscribe({
        next: (cobrandDetails) => {
          this.authService.cobrandDetails$.next(cobrandDetails);
          this.defaultLogo = cobrandDetails.cobrand_asset_urls['logo_2'];
          this.defaultLogoSmall =
            cobrandDetails.cobrand_asset_urls['logo_1'];
        },
        error: (err) => {},
        complete: () => {},
      });
  }

}
