<div class="border-solid border-2 p-4">
  <div class="flex space-x-6">
    <mat-form-field class="grow">
      <mat-label>First Name</mat-label>
      <input matInput autocomplete="off" [(ngModel)]="fName" (mousedown)="$event.stopPropagation()" [appInputMaxLength] />
    </mat-form-field>
    <mat-form-field class="grow">
      <mat-label>Last Name</mat-label>
      <input matInput autocomplete="off" [(ngModel)]="lName" (mousedown)="$event.stopPropagation()" [appInputMaxLength] />
    </mat-form-field>
    <div class="m-2 grow">
      <button mat-button mat-flat-button type="button" color="primary" (click)="searchLead()">Search</button>
    </div>
  </div>
  <div class="flex" *ngIf="isSearch && !fName && !lName">
    <small>You are required to fill at least one field.</small>
  </div>
    <div class="flex" *ngIf="isLoading && !isHavingData">
      <div class="w-full loadingSpinner">
        <app-sms-spinner [size]="'sm'">
          <span>Retrieving search results</span>
        </app-sms-spinner>
      </div>
    </div>
    <ng-container *ngIf="isSearch && (fName || lName) && !isLoading && !isHavingData">
      <p>No results found based on the selected criteria.</p>
    </ng-container>
    <div class="flex">
    <ng-container class="w-full" *ngIf="isHavingData">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="fname">
          <th mat-header-cell *matHeaderCellDef> Lead Name </th>
          <td mat-cell *matCellDef="let row">{{row.fname}} {{row?.lname}} </td>
        </ng-container>
        <ng-container matColumnDef="dob">
          <th mat-header-cell *matHeaderCellDef> DOB </th>
          <td mat-cell *matCellDef="let row">
            {{row?.dob ? (row?.dob | date : 'MM/dd/yyyy') : 'N/A'}} ({{row?.dob ? (row?.dob | agePipe) : ''}})
          </td>
        </ng-container>
        <ng-container matColumnDef="default_address">
          <th mat-header-cell *matHeaderCellDef> Address </th>
          <td mat-cell *matCellDef="let row"> {{row?.default_address?.address1}} </td>
        </ng-container>
        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef> City </th>
          <td mat-cell *matCellDef="let row"> {{row?.default_address?.city}} </td>
        </ng-container>
        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef> State </th>
          <td mat-cell *matCellDef="let row"> {{row?.default_address?.state_id}} </td>
        </ng-container>
        <ng-container matColumnDef="zip">
          <th mat-header-cell *matHeaderCellDef> Zip </th>
          <td mat-cell *matCellDef="let row"> {{row?.default_address?.zip}} </td>
        </ng-container>
        <ng-container matColumnDef="dispo_name">
          <th mat-header-cell *matHeaderCellDef> Disposition </th>
          <td mat-cell *matCellDef="let row"> {{row?.dispo_name}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns ,sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.selected-table-row]="row?.lead_id == selectedRow?.lead_id" (click)="selectRow(row)">
        </tr>
      </table>

    </ng-container>
    </div>
    <mat-paginator class="w-full" #assignLeadPagination [length]="totalRecords" [pageSizeOptions]="[10, 20, 30, 40, 50]">
    </mat-paginator>

</div>
