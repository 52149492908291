<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
  <div cdkDragHandle class="flex justify-between items-baseline pr-4">
    <h2 class="pb-2" mat-dialog-title>User Profile</h2>
    <button type="button" mat-icon-button class="leading-none" mat-dialog-close><mat-icon>close</mat-icon></button>
  </div>

  <mat-divider></mat-divider>
  <mat-dialog-content class="mat-typography">
    <div class="loading-indicator" *ngIf="isLoading">
      <app-sms-spinner></app-sms-spinner>
    </div>

    <div class="update_msg text-center p-4" *ngIf="(hasChange && userData?.user_id && userProfileForm.valid && initialChange); else elseBlock">
      <p class="font-semibold">Changes have been made. Click <span class="upperUpdateLink" (click)="update(userProfileForm.value,'updateOnly')">"Update"</span> to save them</p>
    </div>

    <ng-template #elseBlock>
      <ng-container *ngIf="message?.type">
        <app-sms-notice-banner #messageBanner [type]="message.type" (closeBanner)="clearMessage(0)">
          <p>{{ message.text }}</p>
        </app-sms-notice-banner>
      </ng-container>
    </ng-template>

    <div>
      <form [formGroup]="userProfileForm" autocomplete="off">
        <div class="flex flex-row w-full gap-4">
          <div class="w-1/2">
            <h4 class="pb-2 user-profile-heading">User Information</h4>
            <div class="flex flex-row gap-3">
              <mat-form-field class="w-1/3">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="fname" [appInputMaxLength] appDisableAutofill>
                <mat-error *ngIf="userProfileForm.get('fname')?.errors && submitted">Please enter first name</mat-error>
              </mat-form-field>
              <mat-form-field class="w-1/3">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="lname" [appInputMaxLength] appDisableAutofill>
                <mat-error *ngIf="userProfileForm.get('lname')?.errors && submitted">Please enter last name</mat-error>
              </mat-form-field>
              <p *ngIf="userData?.user_id" class="w-1/3">
                <span class="fw-600 custom-font">Account Owner</span>
                <span [ngClass]="{'account-no': userDetailArray?.user_id != accountDetail?.account_owner_user_id,'account-yes': userDetailArray?.user_id == accountDetail?.account_owner_user_id}">
                  {{isAccountOwner}}</span>
              </p>
            </div>
            <div class="flex flex-row gap-3">
              <mat-form-field class="w-1/2">
                <mat-label>Gender</mat-label>
                <mat-select disableOptionCentering matNativeControl formControlName="gender">
                  <mat-option value="m">Male
                  </mat-option>
                  <mat-option value="f">Female
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="w-1/2">
                <mat-label>Company</mat-label>
                <input matInput formControlName="company" [appInputMaxLength] appDisableAutofill>
              </mat-form-field>
            </div>
            <div *ngIf="isCommonFlag">
              <app-phone formArrayName="phones" [type]="userData.user_id ? 'profile' : 'add-user'"></app-phone>
            </div>
            <div *ngIf="isCommonFlag">
              <app-email formArrayName="emails" [type]="userData.user_id ? 'profile' : 'add-user'"></app-email>
            </div>
            <div *ngIf="isCommonFlag">
              <app-address formArrayName="addresses" [type]="userData.user_id ? 'profile' : 'add-user'"></app-address>
            </div>
          </div>
          <div class="w-1/2 pr-4">
            <div>
              <div class="flex flex-row w-full justify-between">
                <h4 class="pb-2 user-profile-heading">User Details</h4>
                <div class="flex flex-row">
                  <h4 class="pb-2 activate user-profile-heading" *ngIf="userData?.isFromAdmin && userData?.user_id">Activate</h4>
                  <mat-slide-toggle *ngIf="userData?.isFromAdmin && userData?.user_id" class="activate-toggle" [(ngModel)]="userProfileForm.value.active" [ngModelOptions]="{standalone: true}" formControlName="active"></mat-slide-toggle>
                </div>
              </div>
              <div class="flex flex-row w-full gap-3">
                <mat-form-field class="w-[40%]">
                  <mat-label>Username</mat-label>
                  <input matInput formControlName="username" readonly class="not-allowed" [tabindex]="-1">
                </mat-form-field>
                <mat-form-field class="w-[30%]">
                  <mat-label>User ID</mat-label>
                  <input matInput formControlName="user_id" readonly class="not-allowed" [tabindex]="-1">
                </mat-form-field>
                <mat-form-field class="w-[30%]" [title]="userData?.user_id == authService.getToken().user_id ? 'User doesnt have permission to change the role' : ''">
                  <mat-label>Role Type</mat-label>
                  <input matInput formControlName="role_name" *ngIf="userData?.user_id == authService.getToken().user_id" readonly
                  [disabled]="userData?.user_id == authService.getToken().user_id" class="not-allowed" [tabindex]="-1">
                  <mat-select disableOptionCentering matNativeControl formControlName="role_name" *ngIf="userData?.user_id != authService.getToken().user_id">
                    <mat-option *ngFor="let elem of rolesData" [value]="elem.role_name">{{elem.role_name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="inactive_area flex flex-row w-full gap-3">
                <mat-form-field class="w-[40%]">
                  <mat-label> Last Activate/Deactivate Date</mat-label>
                  <input matInput formControlName="last_active_date_local" readonly class="not-allowed" [tabindex]="-1">
                </mat-form-field>
                <mat-form-field class="w-[30%]">
                  <mat-label>Registration Date</mat-label>
                  <input matInput formControlName="registration_date" readonly class="not-allowed" [tabindex]="-1">
                </mat-form-field>
                <mat-form-field class="w-[30%]">
                  <mat-label>Previous Login</mat-label>
                  <input matInput formControlName="last_access_date" readonly class="not-allowed" [tabindex]="-1">
                </mat-form-field>
              </div>
              <div class="flex flex-row w-full gap-3">
                <mat-form-field class="w-[40%]">
                  <mat-label>NPN</mat-label>
                  <input matInput formControlName="npn" [appInputMaxLength]>
                </mat-form-field>
                <mat-form-field class="w-[40%]" [class]="userData?.type!='sms-users' ? 'inactive_area' : ''">
                  <mat-label>Reference ID</mat-label>
                  <input matInput formControlName="reference_id" [class]="userData?.type!='sms-users' ? 'not-allowed': ''" autocomplete="sms-id">
                </mat-form-field>
              </div>
            </div>
            <div>
              <h4 class="pb-2 user-profile-heading">Account</h4>
              <div class="flex flex-row w-full gap-3">
                <mat-form-field class="w-[40%] inactive_area">
                  <mat-label>Account ID</mat-label>
                  <input matInput formControlName="account_id" readonly class="not-allowed" [tabindex]="-1">
                </mat-form-field>
                <mat-form-field class="w-[40%] inactive_area ">
                  <mat-label>Account Owner Name</mat-label>
                  <input matInput formControlName="account_business_name" readonly class="not-allowed" [tabindex]="-1">
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="!userData?.user_id">
              <h4 class="pb-2 user-profile-heading">Username</h4>
              <div class="flex flex-row w-full gap-3">
                <mat-form-field class="w-[50%]">
                  <mat-label>Username</mat-label>
                  <input matInput name="login" maxlength="15" formControlName="loginName" minlength="6" pattern="[a-zA-Z0-9]*">
                  <mat-hint class="hint">Must be between 6-15 alphanumeric characters.</mat-hint>
                  <mat-error *ngIf="userProfileForm.get('loginName')?.invalid">Username must be <strong>6-15 letters or numbers</strong>.</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="!userData?.isFromAdmin || userData?.type == 'sms-users'">
              <h4 class="user-profile-heading">Update Password</h4>
              <div class="showHideCred flex flex-row w-full gap-3">
                <mat-form-field *ngIf="!userData?.isFromAdmin" class="w-[30%]">
                  <mat-label>Current Password</mat-label>
                  <input matInput formControlName="currentpassword" [type]="inputTypeCurrent" autocomplete="new-password">
                  <mat-icon matSuffix (click)="hideShowPassword('current')" class="showHideIcon">{{showCurrentPassword ?'visibility_off':'visibility'}}</mat-icon>
                  <mat-error *ngIf="userProfileForm.get('currentpassword')?.errors?.invalid">The current password entered is incorrect</mat-error>
                </mat-form-field>
                <mat-form-field class="w-[30%]">
                  <mat-label>New Password*</mat-label>
                  <input [type]="inputTypeNew" matInput formControlName="newpassword" autocomplete="new-password">
                  <mat-icon matSuffix (click)="hideShowPassword('new')" class="showHideIcon">{{showNewPassword ?'visibility_off':'visibility'}}</mat-icon>
                </mat-form-field>
                <mat-form-field class="w-[30%]">
                  <mat-label>Confirm Password*</mat-label>
                  <input [type]="inputTypeConfirm" matInput formControlName="confirmpassword" autocomplete="new-password">
                  <mat-icon matSuffix (click)="hideShowPassword('confirm')" class="showHideIcon">{{showConfirmPassword ?'visibility_off':'visibility'}}</mat-icon>
                  <mat-error *ngIf="userProfileForm.get('confirmpassword')?.errors?.confirmedValidator">New Password and Confirm Password must be match.</mat-error>
                </mat-form-field>
              </div>

              <div *ngIf="userProfileForm.get('newpassword')?.errors?.pattern">
                <p>Passwords must accomodate as follows</p>
                <ul>
                  <li>At least 8 characters</li>
                  <li>At least 1 Capital Letter</li>
                  <li>At least 1 Lowercase Letter</li>
                  <li>At least 1 number</li>
                  <li>Must include special characters of &#64; # $ !</li>
                </ul>
                <p>New password does not meet the required criteria.</p>
              </div>
            </div>
            <div *ngIf="isAgentWritingNumber || !userData?.user_id">
              <div *ngIf="isAgentWritingNumber">
                <div class="flex flex-row gap-3">
                  <h4 class="user-profile-heading px-0">Agent Writing Numbers</h4>
                  <button mat-icon-button *ngIf="userData?.type == 'sms-users'" type="button" matTooltip="Add New" (click)="openAgentPopup()">
                    <mat-icon class="align-bottom">add</mat-icon>
                  </button>
                </div>
                <div>
                  <p>No writing numbers</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="flex flex-row w-full">
            <div class="w-1/2" *ngIf="!isAgentWritingNumber && userData?.user_id">
              <button type="button" *ngIf="!isAgentWritingNumber && userData?.user_id" (click)="openMenu()">
                <mat-icon [ngClass]="{'chevron_right' : showSubmenu}" class="align-bottom">
                  expand_more</mat-icon>
                  <span class="user-profile-heading">Agent Writing Numbers</span>
              </button>
            </div>
            <div *ngIf="userData?.type == 'sms-users'" class="flex w-full justify-end">
              <h4 class="user-profile-heading px-0">AWN Sync</h4>
              <mat-slide-toggle class="awn-sync" color="primary" [(ngModel)]="userProfileForm.value.cm_sync_ignore" [ngModelOptions]="{standalone: true}" formControlName="cm_sync_ignore">
              </mat-slide-toggle>
              <h5 class="user-profile-heading px-0">{{userProfileForm.value.cm_sync_ignore == 1 ? 'ON' : 'OFF'}}</h5>
              <div *ngIf="!isAgentWritingNumber" class=" pt-2 pl-2 self-auto">|</div>
              <button *ngIf="!isAgentWritingNumber" type="button" mat-icon-button matTooltip="Add New" (click)="openAgentPopup()">
                <mat-icon>add</mat-icon>
              </button>
              <button *ngIf="!isAgentWritingNumber" type="button" mat-icon-button matTooltip="Delete" (click)="deleteAgentPopup()">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
          <div>
            <div [hidden]="showSubmenu || userData?.type == 'add-user' || !applicationDataSource.filteredData.length">
              <div [hidden]="(isAgentWritingNumber && !userData?.user_id)|| userData?.type == 'add-user' || !applicationDataSource.filteredData.length">
                <div class="short-table-container rounded-md border my-2">
                  <table matSort mat-table [dataSource]="applicationDataSource" #agentWritingSort="matSort">
                    <ng-container matColumnDef="record_num" *ngIf="userData?.type == 'sms-users'">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let row">
                        <mat-checkbox [(ngModel)]="checkboxStates[row.user_carrier_id]" [ngModelOptions]="{standalone: true}" (change)="getRowId($event,row)">&nbsp;&nbsp;</mat-checkbox>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="carrier_full_name">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Carrier Name </th>
                      <td mat-cell *matCellDef="let row"> {{row.carrier_full_name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="product_type_desc">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Type </th>
                      <td mat-cell *matCellDef="let row"> {{row.product_type_desc}} </td>
                    </ng-container>

                    <ng-container matColumnDef="agent_writing_number">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Agent Writing Number
                      </th>
                      <td mat-cell *matCellDef="let row"> {{row.agent_writing_number}} </td>
                    </ng-container>

                    <ng-container matColumnDef="certification_year">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Plan Year(s) </th>
                      <td mat-cell *matCellDef="let row"> {{row.certification_year ? row.certification_year : 'N/A'}}
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="applicationDisplayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: applicationDisplayedColumns;">
                    </tr>
                  </table>
                </div>
                <mat-paginator #agentWritingPagination #agentWritingSort="matSort" matSort [length]="totalRecords" [pageSizeOptions]="[10, 20, 30, 40, 50]">
                </mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="flex w-full justify-end">
      <button *ngIf="!hasChange" mat-button mat-raised-button (click)="captureClose()">Close</button>
      <button *ngIf="hasChange" mat-button mat-raised-button mat-dialog-close>Cancel</button>
      <button mat-button mat-flat-button (click)="update(userProfileForm.value,'updateOnly')" *ngIf="hasChange && initialChange && userProfileForm.valid"
      [disabled]="isLoading">{{!userData?.user_id ?'Add':'Update'}}</button>
      <button mat-button mat-flat-button (click)="update(userProfileForm.value,'close')" *ngIf="hasChange && initialChange && userData?.user_id && userProfileForm.valid" [disabled]="isLoading">Update + Close</button>
    </div>
  </mat-dialog-actions>
</div>
