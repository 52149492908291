import { CommonModule, CurrencyPipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { leadUtilityFunctions } from '@data/services/lead/lead-utility-functions';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgxEditorModule } from 'ngx-editor';
import { PhonePipe } from '../shared/pipes/phone.pipe';
import { DirectivesModule } from './directives/directives.module';
import { HtmlToPlaintextPipe } from './directives/HtmlToPlaintextPipe';
import { MaterialModule } from './material.module';
import { ConfirmDeleteComponent } from './modals/confirm-delete/confirm-delete.component';
import { LoadingWithTextComponent } from './modals/loading-with-text/loading-with-text.component';
import { SimpleSearchComponent } from './modals/simple-search/simple-search.component';
import { AgePipePipe } from './pipes/age-pipe.pipe';
import { ClickPhonePipe } from './pipes/click-phone.pipe';
import { sortByPipe } from './pipes/order-by-name.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SearchPipePipe } from './pipes/search-pipe.pipe';
import { SearchPipe2Pipe } from './pipes/search-pipe2.pipe';
import { AcknowledgeConfirmDialogComponent } from './ui-components/acknowledge-confirm-dialog/acknowledge-confirm-dialog.component';
import { AddressComponent } from './ui-components/address/address.component';
import { EmailComponent } from './ui-components/email/email.component';
import { PhoneComponent } from './ui-components/phone/phone.component';
import { followUpTaskComponent } from './ui-components/followup-task/followup-task.component';
import { SmsNoticeBannerComponent } from './ui-components/sms-notice-banner/sms-notice-banner.component';
import { SmsSpinnerComponent } from './ui-components/sms-spinner/sms-spinner.component';

@NgModule({
  declarations: [
    ConfirmDeleteComponent,
    SmsSpinnerComponent,
    SmsNoticeBannerComponent,
    SafeHtmlPipe,
    HtmlToPlaintextPipe,
    SearchPipePipe,
    SearchPipe2Pipe,
    EmailComponent,
    PhoneComponent,
    AddressComponent,
    followUpTaskComponent,
    AgePipePipe,
    PhonePipe,
    sortByPipe,
    LoadingWithTextComponent,
    SimpleSearchComponent,
    AcknowledgeConfirmDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    DirectivesModule,
    FullCalendarModule,
    NgxEditorModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    SmsSpinnerComponent,
    SmsNoticeBannerComponent,
    MaterialModule,
    DirectivesModule,
    NgxEditorModule,
    SafeHtmlPipe,
    HtmlToPlaintextPipe,
    SearchPipePipe,
    SearchPipe2Pipe,
    EmailComponent,
    PhoneComponent,
    AddressComponent,
    followUpTaskComponent,
    AgePipePipe,
    PhonePipe,
    sortByPipe
  ],
  providers: [CurrencyPipe, leadUtilityFunctions, ClickPhonePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
