<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
  <div cdkDragHandle class="flex justify-between items-baseline pr-4">
    <h2 mat-dialog-title>Important Notice</h2>
    <button type="button" mat-icon-button class="leading-none" mat-dialog-close><mat-icon>close</mat-icon></button>
  </div>

  <mat-divider></mat-divider>

  <div mat-dialog-content>
    <app-sms-notice-banner *ngIf="errorMsg" type="alert" (closeBanner)="clearMessage(0)"><p>{{ errorMsg }}</p></app-sms-notice-banner>

    <div class="w-full flex justify-between">
      <span>Read during this session</span>
      <span *ngIf="notice?.start_date">Posted <span [innerHTML]="displayedMessageFrom(notice.start_date)"></span></span>
    </div>

    <div>
      <p *ngIf="notice?.notice_title" class="notify-title" [innerHTML]="notice.notice_title"></p>
      <div (click)="checkForCMS(notice)" *ngIf="notice?.notice_text" #leadExportDiv [innerHTML]="notice.notice_text | safeHtml" laproDownload></div>
    </div>
  </div>

  <mat-dialog-actions align="end">
    <button type="button" mat-raised-button mat-dialog-close (click)="dismissNotify(notice.notice_id || '')">Close</button>
  </mat-dialog-actions>
</div>
