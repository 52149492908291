<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
  <div cdkDragHandle class="flex justify-between items-baseline pr-4">
        <h2 mat-dialog-title>eSIGNATURE SCOPE OF APPOINTMENT</h2>
           <button type="button" mat-icon-button class="leading-none" mat-dialog-close><mat-icon>close</mat-icon></button>
      </div>
      <mat-divider></mat-divider>
    <div mat-dialog-content>
                <p>The eSignature Scope Of Appointment (SOA) provided here is a CMS-approved, proprietary SMS tool that is not specific
                    to any plan sponsor and can be used for all appointments as per CMS Medicare Marketing Guidelines.</p>

            <form [formGroup]="f2fForms" autocomplete="off">
                <div class="flex gap-4">
                    <mat-form-field class="grow">
                        <mat-label>Appointment Date</mat-label>
                        <input formControlName="aptDate" matInput [matDatepicker]="picker" [min]="minDate" appDate (mousedown)="$event.stopPropagation()"(input)="checkAptDate(f2fForms.value.aptDate)" (dateChange)="checkAptDate(f2fForms.value.aptDate)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="f2fForms.invalid">Please enter a valid date as "MM/DD/YYYY" between today and 1 year.</mat-error>
                    </mat-form-field>
                    <mat-form-field class="grow">
                      <mat-label>Initial Method of Contact</mat-label>
                        <mat-select disableOptionCentering formControlName="contactMethod">
                            <mat-option value="Web" label=" Web"> Web</mat-option>
                            <mat-option value="Email" label="Email">Email </mat-option>
                            <mat-option value="Phone" label="Phone">Phone</mat-option>
                            <mat-option value="Text" label="Text">Text</mat-option>
                            <mat-option value="Walk in" label="Walk in">Walk in</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="flex">
                    <mat-form-field class="w-[50%]">
                      <mat-label>Enter Agent Name Here to eSign</mat-label>
                        <input formControlName="agentSignature" matInput required [appInputMaxLength] 	/>
                    </mat-form-field>
                </div>
            </form>

    </div>
    <div mat-dialog-actions align="end">
        <button mat-raised-button mat-dialog-close>Close</button>
        <button *ngIf="f2fForms.valid" mat-flat-button color="primary" [disabled]="isButtonClick" (click)="f2fSOA()">Continue</button>
    </div>
</div>
