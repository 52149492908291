<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
  <div cdkDragHandle class="flex justify-between items-baseline pr-4">
        <h2 mat-dialog-title *ngIf="event.type == 'add'">Add {{event['tabName']}}</h2>
        <h2 mat-dialog-title *ngIf="event.type != 'add'">{{isMode ? 'Edit' : 'Add'}} {{isAppointment ? 'Appointment' : 'Task'}} </h2>
        <mat-icon class="cursor-pointer" (click)="closeModal()">close</mat-icon>
    </div>
    <mat-divider></mat-divider>
            <ng-container *ngIf="event.type == 'add'">
                    <mat-tab-group mat-align-tabs="start" (selectedTabChange)="tabClick($event)" [selectedIndex]="selectedTabIndex" animationDuration="0ms">
                        <mat-tab label="Appointment">
                            <app-add-appointment (aptChange)="sendAptChange($event)" (addEvent)="onAdd($event)" [isFromCalendar]="calendarData"></app-add-appointment>
                        </mat-tab>
                        <mat-tab label="Task">
                            <app-add-task (taskChange)="sendTaskChange($event)" (addEvent)="onAdd($event)" [isFromCalendar]="calendarData"></app-add-task>
                        </mat-tab>
                    </mat-tab-group>
            </ng-container>
            <ng-container *ngIf="event.type != 'add'">
                <div *ngIf="isAppointment">
                        <app-add-appointment (aptChange)="sendAptChange($event)" [isFromCalendar]="calendarData"></app-add-appointment>
                </div>
                <div *ngIf="!isAppointment">
                        <app-add-task (taskChange)="sendTaskChange($event)" [isFromCalendar]="calendarData"></app-add-task>
                </div>
            </ng-container>
</div>
